import React, { useState, useEffect, useMemo } from "react";
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Chip,
  Pagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WarningIcon from "@mui/icons-material/Warning";
import SpeedIcon from "@mui/icons-material/Speed";
import styled from "styled-components";
import VehicleRouteMap from "../../maps/VehicleRouteMap";
import TripSelector from "../../maps/TripSelector";
import MapControls from "../../maps/MapControls";

const DrawerHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #f5f5f5;
`;

const DrawerContent = styled(Box)`
  width: 95vw;
  height: calc(100vh - 130px);
  display: flex;
  overflow: hidden;
`;

const LeftColumn = styled(Box)`
  max-width: 630px;
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;

  /* Estructura de header-content-footer */
  & > .column-header {
    padding-top: 270px;
    flex: 0 0 auto;
    margin-bottom: 16px;
  }

  & > .column-content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding-right: 8px;

    /* Scrollbar personalizado */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }
  }

  & > .column-footer {
    flex: 0 0 auto;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eee;
  }
`;

const RightColumn = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TripSectionTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  
  &::after {
    content: "";
    flex: 1;
    margin-left: 10px;
    height: 1px;
    background-color: #eee;
  }
`;

const StyledTabs = styled(Tabs)`
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;

  & .MuiTab-root {
    min-width: 120px;
    text-transform: none;
    font-weight: 500;
  }
`;
const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;


const MapDrawer = ({
  open,
  onClose,
  tripData = null,
  selectedDate = null,
  drawerTitle = "Visualización de Viajes",
  viewType = "trips", // 'trips', 'violations', 'speedExcess'
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [page, setPage] = useState(1);
  const [viewFilters, setViewFilters] = useState({
    showSpeedViolations: viewType === "speedExcess",
    showCriticalPoints: viewType === "violations",
    showHarshAccel: false,
    showHarshBraking: false
  });

  // Procesar todos los viajes válidos independientemente del vehículo
  const allTrips = useMemo(() => {
    if (!tripData) return [];

    let processedTrips = [];

    Object.entries(tripData).forEach(([plate, trips]) => {
      const validTrips = trips.filter((trip) => !trip.excedeDistanciaMaxima);

      validTrips.forEach((trip) => {
        processedTrips.push({
          ...trip,
          vehiclePlate: plate,
        });
      });
    });

    return processedTrips;
  }, [tripData]);

  // Número de viajes por página (ajustable según necesidad)
  const tripsPerPage = 4;
  const pageCount = Math.ceil(allTrips.length / tripsPerPage);

  // Viajes para la página actual
  const currentTrips = useMemo(() => {
    const startIndex = (page - 1) * tripsPerPage;
    return allTrips.slice(startIndex, startIndex + tripsPerPage);
  }, [allTrips, page, tripsPerPage]);

  useEffect(() => {
    setViewFilters({
      ...viewFilters,
      showSpeedViolations: viewType === "speedExcess",
      showCriticalPoints: viewType === "violations",
    });
  }, [viewType]);

  useEffect(() => {
    // Seleccionar primer viaje al cargar o cuando cambian los viajes
    if (currentTrips.length > 0 && !selectedTrip) {
      setSelectedTrip(currentTrips[0]);
    }
  }, [currentTrips, selectedTrip]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);

    // Actualizar filtros según la pestaña activa
    switch (newValue) {
      case 0: // Todos los viajes
        setViewFilters({
          showSpeedViolations: true,
          showCriticalPoints: true,
          showHarshAccel: false,
          showHarshBraking: false,
        });
        break;
      case 1: // Violaciones a puntos críticos
        setViewFilters({
          showSpeedViolations: false,
          showCriticalPoints: true,
          showHarshAccel: false,
          showHarshBraking: false,
        });
        break;
      case 2: // Excesos de velocidad
        setViewFilters({
          showSpeedViolations: true,
          showCriticalPoints: false,
          showHarshAccel: false,
          showHarshBraking: false,
        });
        break;
      default:
        setViewFilters({
          showSpeedViolations: true,
          showCriticalPoints: true,
          showHarshAccel: false,
          showHarshBraking: false,
        });
    }
  };

  const handleTripSelection = (trip) => {
    setSelectedTrip(trip);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "95vw", md: "95vw" },
        },
      }}
    >
      <DrawerHeader>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
          >
            {drawerTitle}
          </Typography>
          {selectedDate && (
            <Chip
              label={selectedDate}
              size="small"
              color="default"
              sx={{ ml: 1 }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
            {allTrips.length} viajes disponibles
          </Typography>
          <IconButton
            onClick={onClose}
            aria-label="cerrar"
            sx={{ color: "#666" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DrawerHeader>

      <StyledTabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab icon={<DirectionsCarIcon />} label="Todos los viajes" />
        <Tab icon={<WarningIcon />} label="Puntos críticos" />
        <Tab icon={<SpeedIcon />} label="Excesos de velocidad" />
      </StyledTabs>

      <DrawerContent>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box p={3} textAlign="center" width="100%">
            <Typography color="error">{error}</Typography>
          </Box>
        ) : !tripData || allTrips.length === 0 ? (
          <Box p={3} textAlign="center" width="100%">
            <Typography>
              No hay datos de viajes disponibles para esta fecha.
            </Typography>
          </Box>
        ) : (
          <>
            <LeftColumn>
              {/* Header: Filtros */}
              <div className="column-header">
                <MapControls
                  filters={viewFilters}
                  onFilterChange={setViewFilters}
                />
                
                {/* Título de sección de viajes - Movido aquí abajo del filtro */}
                <TripSectionTitle variant="subtitle1">
                  Viajes disponibles ({currentTrips.length})
                </TripSectionTitle>
              </div>

              {/* Content: Selector de viajes con scroll */}
              <div className="column-content">
                <TripSelector
                  trips={currentTrips}
                  onSelectTrip={handleTripSelection}
                  selectedTrip={selectedTrip}
                  showVehicleInfo={true}
                  hideTitle={true}
                />
              </div>

              {/* Footer: Paginación */}
              {pageCount > 1 && (
                <div className="column-footer">
                  <PaginationContainer>
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size="medium"
                    />
                  </PaginationContainer>
                </div>
              )}
            </LeftColumn>

            <RightColumn>
              <VehicleRouteMap
                allTrips={allTrips}
                date={selectedDate}
                viewFilters={viewFilters}
                selectedTrip={selectedTrip}
                compactMode={true}
              />
            </RightColumn>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default MapDrawer;