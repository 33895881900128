import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import RouteRenderer from "./RouteRenderer";
import EventMarkers from "./EventMarkers";
import "../../assets/scss/components/maps/VehicleRouteMap.scss";
import { Box } from "@mui/material";

// Definir constante para las bibliotecas de Google Maps
const MAP_LIBRARIES = ["geometry", "drawing", "places"];

const VehicleRouteMap = ({
  allTrips,
  date,
  viewFilters,
  selectedTrip,
  compactMode = false,
}) => {
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [filters, setFilters] = useState({
    showSpeedViolations: true,
    showCriticalPoints: true,
    showHarshAccel: false,
    showHarshBraking: false,
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
  }, []);

  const fitBounds = useCallback(() => {
    if (mapRef.current && selectedTrip) {
      const bounds = new window.google.maps.LatLngBounds();

      // Añadir puntos de origen y destino
      if (selectedTrip.origin?.lat && selectedTrip.origin?.lon) {
        bounds.extend(
          new window.google.maps.LatLng(
            selectedTrip.origin.lat,
            selectedTrip.origin.lon
          )
        );
      }

      if (selectedTrip.destination?.lat && selectedTrip.destination?.lon) {
        bounds.extend(
          new window.google.maps.LatLng(
            selectedTrip.destination.lat,
            selectedTrip.destination.lon
          )
        );
      }

      // Si hay polyline de Google Maps
      if (selectedTrip.googleMapsPolyline) {
        try {
          const decodedPath = window.google.maps.geometry.encoding.decodePath(
            selectedTrip.googleMapsPolyline
          );
          decodedPath.forEach((point) => bounds.extend(point));
        } catch (error) {
          console.error("Error decodificando polyline:", error);
        }
      }

      // Añadir todos los puntos de la ruta GPS
      if (selectedTrip.records?.length > 0) {
        selectedTrip.records.forEach((record) => {
          if (record.latitud && record.longitud) {
            try {
              bounds.extend(
                new window.google.maps.LatLng(
                  parseFloat(record.latitud),
                  parseFloat(record.longitud)
                )
              );
            } catch (error) {
              console.error("Error añadiendo punto al bounds:", error);
            }
          }
        });
      }

      // Verificar que bounds tiene puntos antes de aplicarlos
      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds, { padding: 60 });
      } else {
        mapRef.current.setCenter({ lat: 2.7, lng: -74.0 });
        mapRef.current.setZoom(6);
      }
    }
  }, [selectedTrip]);

  // Aplicar filtros
  useEffect(() => {
    if (viewFilters) {
      setFilters(viewFilters);
    }
  }, [viewFilters]);

  // Ajustar mapa cuando cambia selectedTrip
  useEffect(() => {
    if (selectedTrip && mapLoaded) {
      const timer = setTimeout(() => {
        window.google.maps.event.trigger(mapRef.current, "resize");
        fitBounds();
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [selectedTrip, mapLoaded, fitBounds]);

  const calculateCenter = () => {
    if (selectedTrip?.origin && selectedTrip?.destination) {
      return {
        lat:
          (parseFloat(selectedTrip.origin.lat) +
            parseFloat(selectedTrip.destination.lat)) /
          2,
        lng:
          (parseFloat(selectedTrip.origin.lon) +
            parseFloat(selectedTrip.destination.lon)) /
          2,
      };
    }
    return { lat: 2.7, lng: -74.0 }; // Default center for Colombia
  };

  if (!isLoaded) return <div className="map-loading">Cargando el mapa...</div>;

  return (
    <div className="vehicle-route-map-container compact-mode">
      <div className="google-map-container">
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: compactMode ? "calc(100vh - 280px)" : "700px",
          }}
          center={calculateCenter()}
          zoom={9}
          options={{
            clickableIcons: false,
            streetViewControl: false,
            mapTypeId: "terrain",
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: 2,
              position: 3, // RIGHT_TOP
            },
            zoomControlOptions: {
              position: 9, // RIGHT_CENTER
            },
          }}
          onLoad={onMapLoad}
        >
          {selectedTrip && (
            <>
              <RouteRenderer
                trip={selectedTrip}
                googleMapsPolyline={selectedTrip.googleMapsPolyline}
                actualPath={selectedTrip.records || []}
              />

              <EventMarkers trip={selectedTrip} filters={filters} />
            </>
          )}
        </GoogleMap>
      </div>

      {selectedTrip && (
        <div className="trip-details">
          <div className="stats-grid">
            {/* Primera fila - Información de ruta */}
            <div className="stat-group route-info">
              <h4>Información de Ruta</h4>
              <div className="stat-row">
                <div className="stat-item">
                  <span>Ruta:</span>
                  <strong>{selectedTrip.routeName}</strong>
                </div>
                <div className="stat-item">
                  <span>Vehículo:</span>
                  <strong>
                    {selectedTrip.vehiclePlate || "No disponible"}
                  </strong>
                </div>
              </div>
            </div>

            {/* Segunda fila - Distancias */}
            <div className="stat-group distance-info">
              <h4>Distancias y Tiempos</h4>
              <div className="stat-row">
                <div className="stat-item">
                  <span>Distancia GPS:</span>
                  <strong>{selectedTrip.distanceTraveledKm} km</strong>
                </div>
                <div className="stat-item">
                  <span>Distancia Google Maps:</span>
                  <strong>
                    {selectedTrip.googleMapsDistanceKm || "N/A"} km
                  </strong>
                </div>
                <div className="stat-item">
                  <span>Diferencia:</span>
                  <strong>
                    {selectedTrip.googleMapsDistanceKm
                      ? Math.abs(
                          selectedTrip.distanceTraveledKm -
                            (selectedTrip.googleMapsDistanceKm || 0)
                        ).toFixed(2) + " km"
                      : "N/A"}
                  </strong>
                </div>
              </div>
              <div className="stat-row">
                <div className="stat-item">
                  <span>Tiempo Real:</span>
                  <strong>{selectedTrip.durationMinutes} min</strong>
                </div>
                <div className="stat-item">
                  <span>Tiempo Google Maps:</span>
                  <strong>
                    {selectedTrip.googleMapsDurationMinutes || "N/A"} min
                  </strong>
                </div>
                <div className="stat-item">
                  <span>Desviación Promedio:</span>
                  <strong>
                    {selectedTrip.deviationData?.avgDeviationMeters
                      ? `${Math.round(
                          selectedTrip.deviationData.avgDeviationMeters
                        )} m`
                      : "N/A"}
                  </strong>
                </div>
              </div>
            </div>

            {/* Tercera fila - Eventos y alertas */}
            <div className="stat-group events-info">
              <h4>Eventos Detectados</h4>
              <div className="stat-row">
                <div className="stat-item event-stat">
                  <span>Velocidad Máxima:</span>
                  <strong>{selectedTrip.maxSpeed || 0} km/h</strong>
                </div>
                <div className="stat-item event-stat">
                  <span>Excesos de Velocidad:</span>
                  <strong>{selectedTrip.overspeedCount || 0}</strong>
                </div>
                <div className="stat-item event-stat">
                  <span>Violaciones a Puntos Críticos:</span>
                  <strong>{selectedTrip.criticalViolationsCount || 0}</strong>
                </div>
              </div>
              <div className="stat-row">
                <div className="stat-item event-stat">
                  <span>Aceleraciones Bruscas:</span>
                  <strong>{selectedTrip.harshAccelerationCount || 0}</strong>
                </div>
                <div className="stat-item event-stat">
                  <span>Frenadas Bruscas:</span>
                  <strong>{selectedTrip.harshBrakingCount || 0}</strong>
                </div>
                <div className="stat-item event-stat">
                  <span>Eventos de Jamming:</span>
                  <strong>{selectedTrip.jammingCount || 0}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleRouteMap;
