import { useAuth } from "../../context/AuthContext";
import { useLayout } from "../../context/LayoutContext";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/scss/components/sidebar.scss";
import {
  menuRoutes,
  logoutRoute,
  hidePanelRoute,
  helpRoute,
  showPanelRoute,
} from "../../lib/dataRoutes";
import UploadProgress from "../ui/progress/UploadProgress";
import { useSatelliteData } from "../../context/SatelliteDataContext";
import LogotipoCTPS from "../../assets/img/logotipo_cootranspetrols.png";
import LogotipoCTPSRecorte from "../../assets/img/logotivo_v2.png";
import { useEffect, useState, useCallback } from "react";
import { SwitchTheme } from "../../layout/SwitchTheme";
import { Tooltip, createTheme } from "@mui/material";
import ChevronDownIcon from "../../assets/svg/layout/panel_left.svg";
import ChevronDownLightIcon from "../../assets/svg/layout/panel_left_light.svg";
import ChevronRightIcon from "../../assets/svg/layout/panel_right.svg";
import ChevronRightLightIcon from "../../assets/svg/layout/panel_right_light.svg";

export default function Sidebar() {
  const { isNavExpanded, toggleNav, theme, toggleTheme } = useLayout();
  const { uploadProgress, resetProgress } = useSatelliteData();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [indicatorOffset, setIndicatorOffset] = useState(0);
  const [openFolders, setOpenFolders] = useState({});
  const [hoverFolder, setHoverFolder] = useState(null);

  // Detectar carpetas que deben estar abiertas basadas en la ruta actual
  const updateOpenFolders = useCallback(() => {
    if (user?.role_code) {
      const newOpenFolders = { ...openFolders };
      let hasChanges = false;
  
      menuRoutes[user.role_code]?.forEach(route => {
        if (route.isFolder && route.children) {
          // Verificar si la ruta actual está dentro de alguna subruta
          const hasActiveChild = route.children.some(child => 
            // Verificar si la ruta actual comienza con la ruta del hijo
            location.pathname === child.path || 
            location.pathname.startsWith(`${child.path}/`)
          );
          
          if (hasActiveChild && !newOpenFolders[route.name]) {
            newOpenFolders[route.name] = true;
            hasChanges = true;
          }
        }
      });
      
      if (hasChanges) {
        setOpenFolders(newOpenFolders);
      }
    }
  }, [location.pathname, user?.role_code, openFolders]);

  // Actualizar el indicador para la ruta activa
  const updateIndicator = useCallback(() => {
    setTimeout(() => {
      // Primero buscar una ruta activa directa que no sea carpeta
      let activeElement = document.querySelector(
        ".sidebar-body-routes-route.active:not(.sidebar-folder-header)"
      );
      
      // Si no hay ruta directa activa, buscar carpeta cerrada con ruta activa
      if (!activeElement) {
        activeElement = document.querySelector(
          ".sidebar-folder-header.active"
        );
      }
      
      if (activeElement) {
        const parentOffset =
          activeElement.parentElement.getBoundingClientRect().top;
        const activeOffset = activeElement.getBoundingClientRect().top;
        const activeHeight = activeElement.offsetHeight;
        // Ajustar la posición del indicador para que quede centrado verticalmente
        setIndicatorOffset(activeOffset - parentOffset + activeHeight / 2);
      }
    }, 150);
  }, []);

  useEffect(() => {
    updateOpenFolders();
    updateIndicator();
  }, [location.pathname, user?.role_code, updateOpenFolders, updateIndicator]);

  // Actualizar el indicador cuando se abre/cierra una carpeta
  useEffect(() => {
    updateIndicator();
  }, [openFolders, updateIndicator]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  // Modificar función toggleFolder
const toggleFolder = (folderName, event) => {
  event.preventDefault();
  event.stopPropagation();
  
  // Toggle explícito sin restricciones
  setOpenFolders(prev => {
    const newState = { ...prev };
    newState[folderName] = !prev[folderName];
    return newState;
  });
  
  // Forzar actualización del indicador después del toggle
  setTimeout(updateIndicator, 50);
};

  const handleMouseEnter = (folderName) => {
    if (!isNavExpanded) {
      setHoverFolder(folderName);
    }
  };

  const handleMouseLeave = () => {
    if (!isNavExpanded) {
      setHoverFolder(null);
    }
  };

  const getThemeIcon = (darkIcon, lightIcon) => {
    return theme === "dark" ? lightIcon : darkIcon;
  };

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  const renderRouteItem = (route, index, isChild = false) => {
    const currentIcon = getThemeIcon(route.icon, route.iconLight);
    
    // Verificar si la ruta actual es la activa o es una subruta de la ruta actual
    const isActive = location.pathname === route.path || 
                    (route.path && location.pathname.startsWith(`${route.path}/`));
    
    const button = (
      <button
        key={route.path || `route-${index}`}
        type="button"
        className={`sidebar-body-routes-route ${
          isActive ? "active" : ""
        } ${isChild ? "sidebar-folder-item" : ""}`}
        onClick={() => route.path && handleNavigation(route.path)}
      >
        <div className="sidebar-body-routes-route-icon">
          <img src={currentIcon} alt="" />
        </div>
        {isNavExpanded && (
          <span className="sidebar-body-routes-route-name">
            {route.name}
          </span>
        )}
      </button>
    );
    
    return isNavExpanded ? (
      button
    ) : (
      <Tooltip
        key={`tooltip-${index}`}
        title={route.name}
        placement="right"
        arrow
        sx={{
          backgroundColor: theme === "dark" ? "#2A2D34" : "#ffffff",
          color: theme === "dark" ? "#ffffff" : "#1E1E1E",
          fontSize: "0.875rem",
        }}
      >
        {button}
      </Tooltip>
    );
  };

  const renderFolderItem = (route, index) => {
    const currentIcon = getThemeIcon(route.icon, route.iconLight);
    const isOpen = !!openFolders[route.name]; 
    const isHovered = hoverFolder === route.name;
    
    // Verificar si alguno de los hijos tiene la ruta activa
    const hasActiveChild = route.children.some(child => 
      location.pathname === child.path || 
      location.pathname.startsWith(`${child.path}/`)
    );
    
    // La carpeta solo se marca como "active" si contiene una ruta activa Y está cerrada
    const isFolderActive = hasActiveChild && !isOpen;
    
    const chevronDown = getThemeIcon(ChevronDownIcon, ChevronDownLightIcon);
    const chevronRight = getThemeIcon(ChevronRightIcon, ChevronRightLightIcon);
    
    return (
      <div 
        key={`folder-${index}`} 
        className={`sidebar-folder ${isOpen ? "open" : ""} ${hasActiveChild ? "has-active-child" : ""}`}
        onMouseEnter={() => handleMouseEnter(route.name)}
        onMouseLeave={handleMouseLeave}
      >
        <button
          type="button"
          className={`sidebar-body-routes-route sidebar-folder-header ${
            isOpen ? "open" : ""
          } ${isFolderActive ? "active" : ""}`}
          onClick={(e) => toggleFolder(route.name, e)}
        >
          <div className="sidebar-body-routes-route-icon">
            <img src={currentIcon} alt="" />
          </div>
          {isNavExpanded && (
            <>
              <span className="sidebar-body-routes-route-name">
                {route.name}
              </span>
              <span className={`sidebar-folder-arrow ${isOpen ? "rotated" : ""}`}>
                <img 
                  src={isOpen ? chevronDown : chevronRight} 
                  alt={isOpen ? "Colapsar" : "Expandir"} 
                  className={isOpen ? "chevron-down" : "chevron-right"}
                />
              </span>
            </>
          )}
        </button>
        
        <div className={`sidebar-folder-content ${(isOpen || (!isNavExpanded && isHovered)) ? "expanded" : ""}`}>
          {route.children.map((childRoute, childIndex) => (
            renderRouteItem(childRoute, `${index}-${childIndex}`, true)
          ))}
        </div>
      </div>
    );
  };

  const renderRoutes = () => {
    const routes = menuRoutes[user.role_code] || [];
    
    return routes.map((route, index) => {
      // Si es una carpeta y estamos en el rol Admin
      if (route.isFolder && user.role_code === 'Admin') {
        return renderFolderItem(route, index);
      }
      
      // Si es una ruta normal
      return renderRouteItem(route, index);
    });
  };

  const tooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: theme === 'dark' ? '#2A2D34' : '#ffffff',
            color: theme === 'dark' ? '#ffffff' : '#1E1E1E',
            fontSize: '0.875rem',
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
            padding: '8px 12px'
          },
          arrow: {
            color: theme === 'dark' ? '#2A2D34' : '#ffffff'
          }
        }
      }
    }
  });

  return (
    <aside
      className={`sidebar ${isNavExpanded ? "expanded" : "collapsed"} ${theme}`}
    >
      {/* HEADER */}
      <div
        className={`sidebar-header ${isNavExpanded ? "expanded" : "collapsed"}`}
      >
        <div className="sidebar-header-content">
          {isNavExpanded ? (
            <img
              src={LogotipoCTPS}
              alt="Company Logo"
              className="sidebar-header-logo"
            />
          ) : (
            <img
              src={LogotipoCTPSRecorte}
              alt="Company Logo"
              className="sidebar-header-logov2"
            />
          )}
        </div>
      </div>

      {/* BODY */}
      <div className="sidebar-body">
        <div
          className="sidebar-body-routes"
          style={{ "--indicator-offset": `${indicatorOffset}px` }}
        >
          {renderRoutes()}
        </div>
      </div>

      {/* FOOTER - Sections remain unchanged */}
      <div className="sidebar-footer">
        <div className="sidebar-footer-upload">
          {/* UploadProgress component */}
          {isNavExpanded ? (
            <UploadProgress
              percentage={uploadProgress.percentage || 0}
              type="linear"
              fileName={uploadProgress.currentFile || "Sin Proceso"}
              uploadLink="/recursos/videos/conferencia"
              estimatedTime={uploadProgress.estimatedTimeRemaining}
              onReset={resetProgress}
              status={uploadProgress.status}
            />
          ) : (
            <UploadProgress
              percentage={uploadProgress.percentage || 0}
              type="small-circle"
              fileName={uploadProgress.currentFile || "sin proceso"}
              uploadLink="/documentos/documento.pdf"
              estimatedTime={uploadProgress.estimatedTimeRemaining}
              onReset={resetProgress}
              status={uploadProgress.status}
            />
          )}
        </div>
      </div>

      {/* FOOTER - Hide panel / Help buttons */}
      <div className="sidebar-footer">
        {/* Hide/Show panel button */}
        {!isNavExpanded ? (
          <Tooltip title={isNavExpanded ? "Ocultar panel" : "Mostrar panel"} placement="right" arrow>
            <button
              type="button"
              className="sidebar-body-routes-route sidebar-hide-panel"
              onClick={toggleNav}
            >
              <div className="sidebar-body-routes-route-icon">
                <img
                  src={getThemeIcon(showPanelRoute.icon, showPanelRoute.iconLight)}
                  alt="Mostrar panel"
                />
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            className="sidebar-body-routes-route sidebar-hide-panel"
            onClick={toggleNav}
          >
            <div className="sidebar-body-routes-route-icon">
              <img
                src={getThemeIcon(hidePanelRoute.icon, hidePanelRoute.iconLight)}
                alt="Ocultar panel"
              />
            </div>
            <span className="sidebar-body-routes-route-name">
              {hidePanelRoute.name}
            </span>
          </button>
        )}

        {/* Help button */}
        {!isNavExpanded ? (
          <Tooltip title="Ayuda" placement="right" arrow>
            <button
              type="button"
              className="sidebar-body-routes-route"
              onClick={() => handleNavigation(helpRoute.path)}
            >
              <div className="sidebar-body-routes-route-icon">
                <img
                  src={getThemeIcon(helpRoute.icon, helpRoute.iconLight)}
                  alt="Ayuda"
                />
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            className="sidebar-body-routes-route"
            onClick={() => handleNavigation(helpRoute.path)}
          >
            <div className="sidebar-body-routes-route-icon">
              <img
                src={getThemeIcon(helpRoute.icon, helpRoute.iconLight)}
                alt="Ayuda"
              />
            </div>
            <span className="sidebar-body-routes-route-name">
              {helpRoute.name}
            </span>
          </button>
        )}
      </div>

      {/* FOOTER - User info and logout */}
      <div className="sidebar-footer">
        {/* Logout button */}
        {!isNavExpanded ? (
          <Tooltip title="Cerrar sesión" placement="right" arrow>
            <button
              type="button"
              className="sidebar-body-routes-route"
              onClick={handleLogout}
            >
              <div className="sidebar-body-routes-route-icon">
                <img
                  src={getThemeIcon(logoutRoute.icon, logoutRoute.iconLight)}
                  alt="Cerrar sesión"
                />
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            className="sidebar-body-routes-route"
            onClick={handleLogout}
          >
            <div className="sidebar-body-routes-route-icon">
              <img
                src={getThemeIcon(logoutRoute.icon, logoutRoute.iconLight)}
                alt="Cerrar sesión"
              />
            </div>
            <span className="sidebar-body-routes-route-name">
              {logoutRoute.name}
            </span>
          </button>
        )}

        {/* User info */}
        {isNavExpanded && (
          <div className="sidebar-footer-user">
            <span>{user.name}</span>
            <small>{user.role}</small>
          </div>
        )}

        {/* Theme switch */}
        {!isNavExpanded ? (
          <Tooltip title="Cambiar tema" placement="right" arrow>
            <button
              type="button"
              className="sidebar-body-routes-route theme-switch"
              onClick={toggleTheme}
            >
              <div className="sidebar-body-routes-route-icon">
                <SwitchTheme
                  checked={theme === "dark"}
                  onChange={toggleTheme}
                  sx={{
                    m: 0,
                    "& .MuiSwitch-switchBase": {
                      "&.Mui-checked": {
                        transform: "translateX(22px)",
                      },
                    },
                  }}
                />
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            className="sidebar-body-routes-route theme-switch"
            onClick={toggleTheme}
          >
            <div className="sidebar-body-routes-route-icon">
              <SwitchTheme
                checked={theme === "dark"}
                onChange={toggleTheme}
                sx={{
                  m: 0,
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      transform: "translateX(22px)",
                    },
                  },
                }}
              />
            </div>
            <span className="sidebar-body-routes-route-name">Cambiar tema</span>
          </button>
        )}
      </div>
    </aside>
  );
}