import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useGetUser } from "../hooks/useReactQuery";
import "../assets/scss/views/ProfileUser.scss";
import { useAuth } from "../context/AuthContext";
import { mediaService, userService } from "../service";
import ConfirmDeleteModal from "../components/ui/modals/ConfirmDeleteModal";
import MultiDownloadModal from "../components/ui/modals/MultiDownloadModal";
import PreviewModal from "../components/ui/modals/PreviewModal";
import UpdateModal from "../components/ui/modals/UpdateModal";
import DocumentsSectionProfile from "../components/ui/tables/DocumentsSectionProfile";
import { useDocumentTypesByRole } from "../hooks/useFileQuery";
import DocumentFilters, { NON_EXPIRABLE_DOCUMENTS } from "../components/filters/DocumentFilters";
import { useDownload, useFileTransfer } from "../context/DownloadContext";
import UserProfileInfo from "./UserProfileInfo";
import UserProfileEdit from "./UserProfileEdit";
import { useQueryClient } from "@tanstack/react-query";
import {
  NotificationSeverity,
  useNotification,
} from "../context/NotificationContext";
import LoadingAnimation from "../components/loading/LoadingAnimation";
import { useLayout } from "../context/LayoutContext";

// initial stare para la informacion de actulizaicon:
const initialUpdateState = {
  personal: {
    firstName: "",
    firstLastName: "",
    secondLastName: "",
    documentNumber: "",
    documentTypeId: "",
    documentExpDate: "",
    documentExpeditionPlace: "",
    nationality_id: "",
    birthDate: "",
  },
  contact: {
    primaryPhoneNumber: "",
    primaryPhoneCountryId: "",
    secondaryPhoneNumber: "",
    secondaryPhoneCountryId: "",
    email: "",
    location: null,
  },
};

export default function ProfileUser({ id }) {
  const { theme } = useLayout();
  const { showNotification } = useNotification();
  const { handleUploadDocument } = useFileTransfer();
  const queryClient = useQueryClient();
  const { handleDownloadMultipleDocuments } = useDownload();
  const { data, isLoading, error } = useGetUser(id);
  const { user: userLogin } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const { documentTypes, 
          //isLoading: isLoadingDocumentTypes 
        } = useDocumentTypesByRole(data?.data.role_id);

  // Para previsualizar en modal
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  // Para actualizar (ejemplo):
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [docToUpdate, setDocToUpdate] = useState(null);
  const [newFile, setNewFile] = useState(null);

  // descargar todos los archivos
  const [openMultiDownload, setOpenMultiDownload] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]); // IDs seleccionados

  // Eliminar archivo
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);

  const user = data?.data;
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateData, setUpdateData] = useState(initialUpdateState);
  useEffect(() => {
    if (user) {
      setUpdateData({
        personal: {
          firstName: user.first_name || "",
          firstLastName: user.first_last_name || "",
          secondLastName: user.second_last_name || "",
          documentNumber: user.document_number || "",
          documentTypeId: user.document_type_id || "",
          documentExpDate: user.document_exp_date
            ? new Date(user.document_exp_date).toISOString().split("T")[0]
            : "",
          documentExpeditionPlace: user.document_expedition_place || "",
          nationality_id: user.nationality_id || "",
          birthDate: user.birth_date
            ? new Date(user.birth_date).toISOString().split("T")[0]
            : "",
        },
        contact: {
          primaryPhoneNumber: user.primary_phone_number || "",
          primaryPhoneCountryId: user.primary_phone_country_id || "",
          secondaryPhoneNumber: user.secondary_phone_number || "",
          secondaryPhoneCountryId: user.secondary_phone_country_id || "",
          email: user.email || "",
          location: user.location || null,
        },
      });
    }
  }, [user]);

  const prepareDocumentsForDownload = useMemo(() => {
    if (!user?.documents) return { personal: [], certificates: [], others: [] };

    // Definir tipos de documentos personales
    const personalDocTypes = [
      "Documento de identidad",
      "Licencia de Conducción",
      "Foto de Perfil",
      "Contrato Laboral",
    ];

    // Definir tipos de certificados
    const certificateTypes = [
      "Curso de Sustancias Peligrosas",
      "Curso de Seguridad Vial",
      "Curso de Manejo de Extintores y Contraincendios",
      "Curso Mecánica Básica",
      "Curso Primeros Auxilios",
      "Curso de Manejo Defensivo",
      "Curso Trabajo En Alturas",
      "MAE",
      "Módulos Driving",
    ];

    return {
      personal: user.documents.filter((doc) =>
        personalDocTypes.includes(doc.document_type?.name)
      ),
      certificates: user.documents.filter((doc) =>
        certificateTypes.includes(doc.document_type?.name)
      ),
      others: user.documents.filter(
        (doc) =>
          !personalDocTypes.includes(doc.document_type?.name) &&
          !certificateTypes.includes(doc.document_type?.name)
      ),
    };
  }, [user?.documents]);

  if (isLoading)
    return (
      <div>
        <LoadingAnimation />
      </div>
    );
  if (error) return <div>Error al cargar el usuario: {error.message}</div>;
  if (!data) return <div>No se encontró el usuario</div>;

  // -------------------------------------------
  // LÓGICA DE ROLES
  // -------------------------------------------
  const myRole = userLogin?.role_id;
  const profileRole = user?.role_id;
  const myProfile = userLogin?.id === user?.id;
  const permissionLogic = {
    myRole: userLogin?.role_id,
    profileRole: user?.role_id,
    myProfile: userLogin?.id === user?.id,

    canEditProfile: () => {
      const { myRole, profileRole, myProfile } = permissionLogic;
      return myProfile
        ? myRole === 4 || !(myRole === 5 || myRole === 6)
        : myRole === 4 ||
            (myRole === 1 && (profileRole === 5 || profileRole === 6)) ||
            (myRole === 2 && (profileRole === 5 || profileRole === 6));
    },

    canDownloadDocs: () => {
      const { profileRole, myRole } = permissionLogic;
      return !(profileRole === 4 && myRole !== 4);
    },

    canUpdateOrDeleteDocs: () => permissionLogic.canEditProfile(),
  };

  const canEditProfile = () =>
    myProfile
      ? myRole === 4 || !(myRole === 5 || myRole === 6)
      : myRole === 4 ||
        (myRole === 1 && (profileRole === 5 || profileRole === 6)) ||
        (myRole === 2 && (profileRole === 5 || profileRole === 6));

  const canDownloadDocs = () => !(profileRole === 4 && myRole !== 4);
  const canUpdateOrDeleteDocs = () => canEditProfile();

  // -------------------------------------------
  // FILTRAR DOCUMENTOS
  // -------------------------------------------
  const getFilteredDocuments = () => {
    if (!user.documents) return { documents: [], types: [] };

    let filtered = [...user.documents];
    let relevantTypes = [...(documentTypes || [])];

    // Aplicar filtro de búsqueda
    if (searchValue.trim()) {
      const searchTerm = searchValue.toLowerCase();
      
      filtered = filtered.filter(
        (doc) =>
          // Buscar en descripción del documento
          doc.original_name_description
            ?.toLowerCase()
            .includes(searchTerm) ||
          // Buscar en nombre del archivo
          doc.file_name?.toLowerCase().includes(searchTerm) ||
          // Buscar en el nombre del tipo de documento
          doc.document_type?.name?.toLowerCase().includes(searchTerm)
      );
      
      // Actualizar los tipos relevantes basados en los documentos filtrados
      relevantTypes = relevantTypes.filter((type) =>
        // Incluir el tipo si hay documentos de ese tipo en los filtrados
        filtered.some((doc) => doc.document_type?.id === type.id) ||
        // O si el nombre del tipo coincide con la búsqueda
        type.name?.toLowerCase().includes(searchTerm)
      );
    }

    // Aplicar filtro de tipo
    switch (filterValue) {
      case "expiration":
        const now = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(now.getDate() + 30);
      
        filtered = filtered.filter((doc) => {
          // Excluir documentos no expirables
          if (NON_EXPIRABLE_DOCUMENTS.includes(doc.document_type?.name)) {
            return false;
          }
          
          if (!doc.expiration_date) return false;
          const expirationDate = new Date(doc.expiration_date);
          return expirationDate <= thirtyDaysFromNow;
        });
      
        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type?.id === type.id)
        );
        break;
      case "personal":
        const personalDocTypes = ["Foto de Perfil", "Documento de identidad", "Licencia de Conducción"];
        filtered = filtered.filter((doc) =>
          personalDocTypes.includes(doc.document_type?.name)
        );
        relevantTypes = relevantTypes.filter((type) =>
          personalDocTypes.includes(type.name)
        );
        break;
        case "empty":
          // Incluir tipos vacíos y mantener documentos temporales para edición
          const emptyTypes = documentTypes?.filter((type) => {
            // Obtener todos los documentos de este tipo
            const docsOfThisType = user.documents.filter(
              doc => doc.document_type?.id === type.id
            );
            
            // Si no hay documentos, este tipo está vacío
            if (docsOfThisType.length === 0) return true;
            
            // Si solo tiene documentos temporales, también se considera vacío
            const allDocsAreTemporary = docsOfThisType.every(
              doc => doc.id?.startsWith("temp-") || doc.isLocal === true
            );
            
            return allDocsAreTemporary;
          }) || [];
          
          // Mantener documentos temporales en el resultado filtrado
          filtered = user.documents.filter(doc => 
            // Incluir solo documentos temporales
            (doc.id?.startsWith("temp-") || doc.isLocal === true) &&
            // Y que pertenezcan a alguno de los tipos vacíos
            emptyTypes.some(type => type.id === doc.document_type?.id)
          );
          
          relevantTypes = emptyTypes;
          break;
      case "required":
        filtered = filtered.filter((doc) => doc.required);
        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type?.id === type.id)
        );
        break;
      case "not-required":
        filtered = filtered.filter((doc) => !doc.required);
        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type?.id === type.id)
        );
        break;
      default:
        break;
    }

    return {
      documents: filtered,
      types: relevantTypes,
    };
  };

  // -------------------------------------------
  // Handlers de documentos
  // -------------------------------------------
  const handleUploadNewDocument = async (newDoc) => {
    if (!newDoc || (!newDoc.file && !newDoc.id?.startsWith("temp-"))) {
      showNotification({
        message: "El documento debe tener un archivo",
        severity: NotificationSeverity.WARNING,
      });
      return;
    }

    try {
      // Asegurar que tenemos el userId en el documento
      const documentToUpload = {
        ...newDoc,
        userId: data?.data?.id,
      };

      // Usamos el nuevo método de contexto para mostrar el progreso de subida
      const response = await handleUploadDocument(documentToUpload);

      showNotification({
        message: "Documento agregado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });

      // Refrescar datos del usuario
      queryClient.invalidateQueries(["user", id]);
      return response;
    } catch (err) {
      console.error("Error al subir documento:", err);

      // Manejo de errores específicos
      if (err.validationErrors) {
        const errorMessages = Object.values(err.validationErrors).join(", ");
        showNotification({
          message: `Validación fallida: ${errorMessages}`,
          severity: NotificationSeverity.ERROR,
        });
      } else {
        showNotification({
          message: err.message || "Error al subir el documento",
          severity: NotificationSeverity.ERROR,
        });
      }

      queryClient.invalidateQueries(["user", id]);
      throw err;
    }
  };
  const documentHandlers = {
    handlePreview: (doc) => {
      setSelectedDoc(doc);
      setOpenPreview(true);
    },

    handleUpdate: (doc) => {
      if (!canUpdateOrDeleteDocs()) return;
      handleUpdateDoc(doc);
    },

    handleDelete: (doc) => {
      if (!canUpdateOrDeleteDocs()) return;
      setDocToDelete(doc);
      setOpenDeleteDialog(true);
    },

    handleAddDocument: (newDoc) => {
      if (!canUpdateOrDeleteDocs()) return;

      // Verificamos que el documento es válido
      if (!newDoc) return;

      // Crear una copia de los documentos actuales
      const updatedDocuments = [...user.documents, newDoc];

      // Actualizar UI local mediante React Query
      queryClient.setQueryData(["user", id], {
        ...data,
        data: {
          ...user,
          documents: updatedDocuments,
        },
      });
    },

    handleDocumentChange: (action) => {
      if (!canUpdateOrDeleteDocs()) return;
      const userId = id;

      switch (action.type) {
        case "ADD_DOCUMENT":
          handleUploadNewDocument(action.payload);
          break;
        case "VALIDATION_ERROR":
          showNotification({
            message:
              action.payload.message || "Error de validación en el documento",
            severity: NotificationSeverity.WARNING,
          });
          break;
        case "UPDATE_DOCUMENT":
          // Actualizar localmente para reflejar cambios inmediatamente
          const updatedDocs = user.documents.map((doc) =>
            doc.id === action.payload.docId
              ? { ...doc, [action.payload.field]: action.payload.value }
              : doc
          );

          // Actualizar la UI local
          queryClient.setQueryData(["user", userId], {
            ...data,
            data: {
              ...user,
              documents: updatedDocs,
            },
          });
          break;

        case "DELETE_DOCUMENT":
          // Este caso es para eliminar documentos del backend
          if (!docToDelete?.isLocal && !docToDelete?.id?.startsWith("temp-")) {
            confirmDeleteDoc();
          }
          break;

        case "DELETE_LOCAL_DOCUMENT":
          // Para documentos locales, solo eliminamos del estado sin llamar al backend
          const filteredDocs = user.documents.filter(
            (doc) => doc.id !== action.payload.docId
          );

          // Actualizar UI local
          queryClient.setQueryData(["user", userId], {
            ...data,
            data: {
              ...user,
              documents: filteredDocs,
            },
          });
          break;

        default:
          console.warn("Acción no reconocida:", action.type);
      }
    },
  };

  const handleDownload = async (doc) => {
    if (!canDownloadDocs()) return;
    try {
      await mediaService.downloadDocument(doc.id);
    } catch (err) {
      console.error("Error al descargar documento:", err);
    }
  };
  const handleDownloadAll = async () => {
    if (selectedDocs.length === 0) {
      showNotification({
        message: "No hay documentos seleccionados",
        severity: NotificationSeverity.WARNING,
      });
      return;
    }

    try {
      await handleDownloadMultipleDocuments(selectedDocs, 1);
      showNotification({
        message: "Descarga completada exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (err) {
      showNotification({
        message: "Error al descargar los documentos",
        severity: NotificationSeverity.ERROR,
      });
    }
  };
  // -- Para actualizar un documento:
  const handleUpdateDoc = async (doc) => {
    if (!canUpdateOrDeleteDocs()) return;

    try {
      // Preparar los datos para actualización
      const updatePayload = {
        docType: doc.document_type?.id || doc.docType,
        description: doc.original_name_description || "",
        required: doc.required || false,
        expeditionDate: doc.expedition_date,
        expirationDate: doc.expiration_date,
        userId: data?.data?.id,
      };

      // Si hay un nuevo archivo, incluirlo
      if (doc.file && doc.file instanceof File) {
        updatePayload.file = doc.file;
        updatePayload.fileName = doc.file.name;
      }
      await mediaService.updateDocument(doc.id, updatePayload);

      showNotification({
        message: "Documento actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });

      // Refrescar datos
      queryClient.invalidateQueries(["user", id]);
    } catch (err) {
      showNotification({
        message:
          err.response?.data?.message || "Error al actualizar el documento",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setDocToUpdate(null);
    setNewFile(null);
  };
  const confirmDeleteDoc = async () => {
    if (!docToDelete) return;
    try {
      await mediaService.deleteDocument(docToDelete.id);
      showNotification({
        message: "Documento eliminado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
      queryClient.invalidateQueries(["user", id]);
    } catch (err) {
      showNotification({
        message: "Error al eliminar el documento",
        severity: NotificationSeverity.ERROR,
      });
    } finally {
      setOpenDeleteDialog(false);
      setDocToDelete(null);
    }
  };

  const handleConfirmUpdate = async () => {
    if (!newFile || !docToUpdate) return;
    try {
      const formData = new FormData();
      formData.append("file", newFile);

      await mediaService.updateDocument(docToUpdate.id, formData);
      showNotification({
        message: "Documento actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
      queryClient.invalidateQueries(["user", id]);
      handleCloseUpdateDialog();
    } catch (err) {
      showNotification({
        message: "Error al actualizar el documento",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  // -------------------------------------------
  // Manejo de edicion de perfil
  // -------------------------------------------

  const toggleEditMode = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }
    setIsEditing(true);
  };

  // Otros documentos para mostrar el el dowload all

  // Modificar handleSubmit

  const handleUpdateChange = (section, field, value) => {
    setUpdateData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    setIsUpdating(true);
    try {
      const payload = {
        first_name: updateData.personal.firstName,
        first_last_name: updateData.personal.firstLastName,
        second_last_name: updateData.personal.secondLastName,
        document_number: updateData.personal.documentNumber,
        document_type_id: updateData.personal.documentTypeId,
        document_exp_date: updateData.personal.documentExpDate,
        document_expedition_place: updateData.personal.documentExpeditionPlace,
        birth_date: updateData.personal.birthDate,
        nationality_id: updateData.personal.nationality_id,
        email: updateData.contact.email,
        primary_phone_number: updateData.contact.primaryPhoneNumber,
        primary_phone_country_id: updateData.contact.primaryPhoneCountryId,
        secondary_phone_number: updateData.contact.secondaryPhoneNumber,
        secondary_phone_country_id: updateData.contact.secondaryPhoneCountryId,
      };

      const response = await userService.updateUser(id, payload);

      if (response.success) {
        queryClient.invalidateQueries(["user", id]);
        setIsEditing(false);
        showNotification({
          message: "Perfil actualizado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      }
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al actualizar el perfil",
        severity: NotificationSeverity.ERROR,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleOpenMultiDownload = (e) => {
    e.preventDefault();
    setOpenMultiDownload(true);
  };

  const filteredData = getFilteredDocuments();
  const profileImageId = user.documents.find(
    (doc) => doc.file_type === "Foto de Perfil"
  )?.id;
  return (
    <div className="profile-user">
      {!isEditing ? (
        <UserProfileInfo
          user={user}
          canEditProfile={canEditProfile()}
          onEditClick={toggleEditMode}
          profileImageId={profileImageId}
          className="profile-image"
          theme={theme}
        />
      ) : (
        <UserProfileEdit
          updateData={updateData}
          onUpdateChange={handleUpdateChange}
          onSubmit={handleSubmit}
          onCancel={toggleEditMode}
          profileImageId={profileImageId}
          isUpdating={isUpdating}
          theme={theme}
        />
      )}

      <div className="multi-download-container">
        <DocumentFilters
          documents={user.documents}
          onSearchChange={(e) => setSearchValue(e.target.value)}
          onFilterChange={(e) => setFilterValue(e.target.value)}
          searchValue={searchValue}
          filterValue={filterValue}
          onExpirationFilterClick={() => setFilterValue("expiration")}
          handleOpenMultiDownload={handleOpenMultiDownload}
          theme={theme}
        />
      </div>

      {/* Documentos Personales */}
      <DocumentsSectionProfile
        documents={filteredData.documents}
        dataTypes={filteredData.types}
        onDocumentsChange={documentHandlers.handleDocumentChange}
        isLoading={isLoading}
        canEdit={permissionLogic.canUpdateOrDeleteDocs()}
        onPreview={documentHandlers.handlePreview}
        onUpdate={documentHandlers.handleUpdate}
        onDelete={documentHandlers.handleDelete}
        onAddDocument={documentHandlers.handleAddDocument}
        theme={theme}
      />

      {/* MODALES (sólo se renderizan, la lógica está en el padre) */}

      {/* PREVIEW MODAL */}
      <PreviewModal
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setSelectedDoc(null);
        }}
        doc={selectedDoc}
      />

      {/* UPDATE MODAL */}
      <UpdateModal
        open={openUpdateDialog}
        onClose={() => {
          setOpenUpdateDialog(false);
          setDocToUpdate(null);
          setNewFile(null);
        }}
        docToUpdate={docToUpdate}
        newFile={newFile}
        setNewFile={setNewFile}
        onConfirmUpdate={handleConfirmUpdate}
      />

      {/* MULTI DOWNLOAD MODAL */}
      <MultiDownloadModal
        open={openMultiDownload}
        onClose={() => setOpenMultiDownload(false)}
        personalDocuments={prepareDocumentsForDownload.personal}
        certificates={prepareDocumentsForDownload.certificates}
        otherDocuments={prepareDocumentsForDownload.others}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
        onDownloadAll={handleDownloadAll}
        userName={`${user.first_name} ${user.first_last_name} ${user.second_last_name}`}
      />

      {/* ELIMINAR (REUTILIZABLE) */}
      <ConfirmDeleteModal
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDocToDelete(null);
        }}
        documentHandlers
        onConfirm={confirmDeleteDoc}
        title="Eliminar Documento"
        message={`¿Seguro que deseas eliminar el documento "${docToDelete?.name}"?`}
      />
    </div>
  );
}

ProfileUser.propTypes = {
  id: PropTypes.string.isRequired,
};
