import axios from "axios";
//const API_URL = process.env.REACT_APP_API_URL;
const { v4: uuidv4 } = require('uuid');
const API_URL = process.env.REACT_APP_API_URL_PRODUCTION;

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

// Utilidad para comprimir datos
const compressData = (data) => {
  try {
    return JSON.stringify(data).replace(/\s+/g, " ");
  } catch (error) {
    console.error("Error comprimiendo datos:", error);
    return data;
  }
};

// Utilidad para calcular el tamaño aproximado de un objeto en bytes
const calculateObjectSize = (obj) => {
  const str = JSON.stringify(obj);
  // Asumiendo UTF-16
  return str.length * 2;
};

export const satelliteService = {
  saveProcessedData: async (processedData, onProgress) => {
    const delay = async (ms, message) => {
      console.log(message);
      await new Promise((resolve) => setTimeout(resolve, ms));
    };

    const calculateProgress = (
      currentChunk,
      totalChunks,
      chunkProgress = 100
    ) => {
      // Cada chunk representa una porción igual del progreso total
      const chunkWeight = 100 / totalChunks;
      // El progreso total es la suma del progreso de los chunks anteriores más el progreso actual
      const totalProgress =
        (currentChunk - 1) * chunkWeight + chunkWeight * (chunkProgress / 100);

      return {
        percentage: Math.min(Math.round(totalProgress), 100),
        currentChunk: currentChunk,
        totalChunks: totalChunks,
        status: "processing",
      };
    };

    const createOptimalChunks = (vehicles) => {
      const chunks = [];
      let currentChunk = {};
      let currentSize = 0;
      const MAX_CHUNK_SIZE = 1 * 1024 * 1024; // 1MB máximo por chunk

      Object.entries(vehicles).forEach(([key, value]) => {
        const itemSize = calculateObjectSize({ [key]: value });

        if (currentSize + itemSize > MAX_CHUNK_SIZE) {
          if (Object.keys(currentChunk).length > 0) {
            chunks.push(currentChunk);
          }
          currentChunk = { [key]: value };
          currentSize = itemSize;
        } else {
          currentChunk[key] = value;
          currentSize += itemSize;
        }
      });

      if (Object.keys(currentChunk).length > 0) {
        chunks.push(currentChunk);
      }

      return chunks;
    };

    try {
      const compressedData = {
        ...processedData,
        vehicles: processedData.vehicles || {},
      };

      // Generar un ID de sesión para esta operación de guardado
      const sessionId = uuidv4();
      const vehicleChunks = createOptimalChunks(compressedData.vehicles);
      const totalChunks = vehicleChunks.length;
      const results = [];

      //console.log(`Procesando ${totalChunks} chunks de datos...`);

      // Notificar inicio del proceso
      onProgress({
        percentage: 0,
        currentChunk: 0,
        totalChunks,
        sessionId,
        status: "processing",
        message: "Iniciando procesamiento de datos",
      });

      for (let i = 0; i < totalChunks; i++) {
        const chunk = {
          ...compressedData,
          vehicles: vehicleChunks[i],
          chunkInfo: {
            current: i + 1,
            total: totalChunks,
            vehiclesInChunk: Object.keys(vehicleChunks[i]).length,
            approximateSize: `${(
              calculateObjectSize(vehicleChunks[i]) / 1024
            ).toFixed(2)}KB`,
          },
        };

        let retryCount = 0;
        let success = false;

        while (retryCount < MAX_RETRIES && !success) {
          try {
            if (retryCount > 0) {
              const retryMessage = `Reintentando chunk ${
                i + 1
              }/${totalChunks} (intento ${retryCount + 1}/${MAX_RETRIES})`;
              await delay(RETRY_DELAY * retryCount, retryMessage);
              onProgress({
                ...calculateProgress(i + 1, totalChunks),
                message: retryMessage,
              });
            }

            const response = await axios.post(
              `${API_URL}/save-processed-data`,
              compressData(chunk),
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "X-Requested-With": "XMLHttpRequest",
                  "X-Chunk-Number": `${i + 1}`,
                  "X-Total-Chunks": `${totalChunks}`,
                  "X-Save-Session-Id": sessionId,
                },
                timeout: 60000,
                maxContentLength: 2 * 1024 * 1024,
                maxBodyLength: 2 * 1024 * 1024,
                onUploadProgress: (progressEvent) => {
                  const chunkProgress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );

                  onProgress({
                    ...calculateProgress(i + 1, totalChunks, chunkProgress),
                    message: `Subiendo chunk ${
                      i + 1
                    }/${totalChunks}: ${chunkProgress}%`,
                  });
                },
              }
            );

            success = true;
            results.push({
              chunk: i + 1,
              success: true,
              size: chunk.chunkInfo.approximateSize,
              data: response.data,
            });

            onProgress({
              ...calculateProgress(i + 1, totalChunks),
              message: `Chunk ${i + 1}/${totalChunks} procesado correctamente`,
            });

            if (i < totalChunks - 1) {
              await delay(1000, "Esperando antes del siguiente chunk...");
            }
          } catch (error) {
            retryCount++;
            console.error(
              `❌ Error en chunk ${
                i + 1
              }/${totalChunks} (intento ${retryCount}):`,
              error.message
            );

            if (error.response?.status === 413) {
              const errorMessage = `Chunk demasiado grande (${chunk.chunkInfo.approximateSize}). Considerando reducir CHUNK_SIZE.`;
              console.error(errorMessage);
              onProgress({
                ...calculateProgress(i + 1, totalChunks),
                status: "error",
                message: errorMessage,
              });
            }

            if (retryCount === MAX_RETRIES) {
              try {
                // Solicitar rollback explícito
                await axios.post(`${API_URL}/rollback-save-session`, null, {
                  headers: {
                    "X-Save-Session-Id": sessionId
                  }
                });
                console.log(`Rollback explícito solicitado para la sesión ${sessionId}`);
              } catch (rollbackError) {
                console.error("Error al solicitar rollback:", rollbackError);
              }
              results.push({
                chunk: i + 1,
                success: false,
                size: chunk.chunkInfo.approximateSize,
                error: error.response?.data || error.message,
              });

              throw {
                message: `Error en el chunk ${
                  i + 1
                }/${totalChunks} después de ${MAX_RETRIES} intentos`,
                error: error.response?.data || error.message,
                results,
                failedChunkSize: chunk.chunkInfo.approximateSize,
              };
            }
          }
        }
      }

      // Notificar finalización exitosa
      onProgress({
        percentage: 100,
        currentChunk: totalChunks,
        totalChunks,
        status: "complete",
        message: "Proceso completado exitosamente",
      });

      return {
        success: true,
        totalChunks,
        totalProcessedSize: results.reduce((acc, curr) => {
          return acc + parseFloat(curr.size);
        }, 0),
        results,
      };
    } catch (error) {
      console.error("Error en el proceso de guardado:", error);

      // Notificar error
      onProgress({
        percentage: 0,
        status: "error",
        message: `Error: ${error.message}`,
      });

      throw error;
    }
  },
  getMonthlyVehicleAnalytics: async (date) => {
    try {
      // Validación de la fecha
      if (!date || !isValidDateFormat(date)) {
        throw new Error("Formato de fecha inválido. Use YYYY-MM-DD");
      }

      // Extraer mes, año y el dia de la fecha proporcionada
      const [year, month] = date.split("-");

      const response = await axios.get(`${API_URL}/monthly-vehicle-analytics`, {
        params: { month, year }, // Envío como parámetros de consulta
        headers: {
          "Content-Type": "application/json",
          // Añade aquí tus headers de autorización si es necesario
          // 'Authorization': `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error obteniendo analíticas mensuales de vehículos:",
        error
      );
      throw error.response?.data || error.message;
    }
  },
  getDailyVehicleAnalytics: async (date) => {
    try {
      // Validación de la fecha (YYYY-MM-DD)
      if (!date || !isValidDateFormat(date)) {
        throw new Error("Formato de fecha inválido. Use YYYY-MM-DD");
      }

      // Extraer día, mes, año
      const [year, month, day] = date.split("-");
      const response = await axios.get(`${API_URL}/daily-vehicle-analytics`, {
        params: {
          day,
          month,
          year,
        },
        headers: {
          "Content-Type": "application/json",
          // Añade aquí tus headers de autorización si es necesario
          // 'Authorization': `Bearer ${token}`
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error obteniendo analíticas diarias de vehículos:", error);
      throw error.response?.data || error.message;
    }
  },
  uploadExcelFile: async (formData) => {
    try {
      const response = await axios.post(
        `${API_URL}/convert-excel-to-json`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  getAnnualVehicleAnalytics: async (date) => {
    try {
      // Validación de la fecha
      if (!date || !isValidDateFormat(date)) {
        throw new Error("Formato de fecha inválido. Use YYYY-MM-DD");
      }

      // Extraer mes y año de la fecha proporcionada
      const [year, month] = date.split("-");

      const response = await axios.get(`${API_URL}/annual-vehicle-analytics`, {
        params: { month, year }, // Envío como parámetros de consulta
        headers: {
          "Content-Type": "application/json",
          // Añade aquí tus headers de autorización si es necesario
          // 'Authorization': `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error obteniendo analíticas mensuales de vehículos:",
        error
      );
      throw error.response?.data || error.message;
    }
  },
  getAnnualHotspots: async (date) => {
    try {
      // Validación de la fecha
      if (!date || !isValidDateFormat(date)) {
        throw new Error("Formato de fecha inválido. Use YYYY-MM-DD");
      }

      // Extraer mes y año de la fecha proporcionada
      const [year, month] = date.split("-");

      const response = await axios.get(
        `${API_URL}/critical-point-violations-annual`,
        {
          params: { month, year }, // Envío como parámetros de consulta
          headers: {
            "Content-Type": "application/json",
            // Añade aquí tus headers de autorización si es necesario
            // 'Authorization': `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error obteniendo analíticas mensuales de vehículos:",
        error
      );
      throw error.response?.data || error.message;
    }
  },
};
export const userService = {
  registerUser: async (userData) => {
    try {
      // Función para procesar el archivo y convertirlo a Base64
      const processFile = async (file) => {
        if (!file) return null;
        // Si ya tenemos el contenido en base64, lo retornamos directamente
        if (typeof file === "string" && file.startsWith("data:")) {
          return file.split(",")[1];
        }
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(",")[1]);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      // Procesar documentos
      const processDocuments = async (documents) => {
        if (!documents || !Array.isArray(documents)) {
          console.warn("No hay documentos para procesar");
          return [];
        }

        try {
          const processedDocs = await Promise.all(
            documents.map(async (doc) => {
              if (!doc || !doc.file) return null;

              try {
                const base64Content = await processFile(doc.file);
                return {
                  documentTypeId: doc.docType,
                  fileName: doc.fileName,
                  originalName: doc.file.name,
                  fileType: doc.file.type,
                  fileSize: doc.file.size,
                  content: base64Content,
                  expeditionDate: doc.expeditionDate || null,
                  expirationDate: doc.expirationDate || null,
                  originalNameDescription: doc.description || "",
                  required: Boolean(doc.required),
                };
              } catch (docError) {
                console.error(
                  `Error procesando documento: ${doc.fileName}`,
                  docError
                );
                return null;
              }
            })
          );

          // Filtrar documentos nulos
          const validProcessedDocs = processedDocs.filter(
            (doc) => doc !== null
          );

          // Procesar la foto de perfil si existe
          if (userData?.personalInfo?.profilePhoto) {
            let profilePhotoContent;

            // Verificar si ya tenemos el contenido en base64
            if (userData.personalInfo.profilePhoto.content) {
              profilePhotoContent =
                userData.personalInfo.profilePhoto.content.split(",")[1];
            } else {
              profilePhotoContent = await processFile(
                userData.personalInfo.profilePhoto.file
              );
            }

            validProcessedDocs.push({
              documentTypeId: userData.personalInfo.profilePhoto.documentTypeId,
              fileName: userData.personalInfo.profilePhoto.name,
              originalName: userData.personalInfo.profilePhoto.name,
              fileType: userData.personalInfo.profilePhoto.type,
              fileSize: userData.personalInfo.profilePhoto.size,
              content: profilePhotoContent,
              originalNameDescription: "Foto de perfil",
              required: true,
            });
          }

          return validProcessedDocs;
        } catch (error) {
          console.error("Error en el procesamiento de documentos:", error);
          return [];
        }
      };

      // Procesar datos personales
      const processedPersonalInfo = {
        firstName: userData.personalInfo.firstName,
        firstLastName: userData.personalInfo.firstLastName,
        secondLastName: userData.personalInfo.secondLastName,
        birthDate: userData.personalInfo.birthDate,
        documentNumber: userData.personalInfo.documentNumber,
        documentExpDate: userData.personalInfo.documentExpDate,
        documentTypeId: userData.personalInfo.documentTypeId,
        documentExpeditionPlace: userData.personalInfo.documentExpeditionPlace,
        nationality: userData.personalInfo.nationality,
        limitedNotifications: userData.personalInfo.limitedNotifications,
        ownFleet: userData.personalInfo.ownFleet,
      };

      // Procesar información de contacto
      const processedContactInfo = {
        primaryPhoneNumber: userData.contactInfo.primaryPhoneNumber,
        primaryPhoneCountryId: userData.contactInfo.primaryPhoneCountryId,
        secondaryPhoneNumber: userData.contactInfo.secondaryPhoneNumber,
        secondaryPhoneCountryId: userData.contactInfo.secondaryPhoneCountryId,
        email: userData.contactInfo.email,
        contactName: userData.contactInfo.contactName,
      };

      // Procesar los documentos antes de construir el payload
      const processedDocuments = await processDocuments(userData.documents);

      // Construir payload final
      const processedUserData = {
        personalInfo: processedPersonalInfo,
        contactInfo: processedContactInfo,
        roleId: userData.roleId,
        location: userData.contactInfo.location,
        documents: processedDocuments,
      };

      const response = await axios.post(
        `${API_URL}/register`,
        processedUserData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error en el registro:", error);
      throw error.response?.data || error.message;
    }
  },
  updateUser: async (userId, userData) => {
    try {
      const response = await axios.put(`${API_URL}/user/${userId}`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.data.success) {
        throw new Error(response.data.message || "Error al actualizar usuario");
      }

      return response.data;
    } catch (error) {
      // Manejo de errores específicos
      if (error.response) {
        // Error de respuesta del servidor
        throw {
          message: error.response.data.message || "Error al actualizar usuario",
          status: error.response.status,
          details: error.response.data,
        };
      } else if (error.request) {
        // Error de red
        throw {
          message: "Error de conexión con el servidor",
          status: 503,
          details: error.request,
        };
      } else {
        // Otros errores
        throw {
          message: error.message || "Error desconocido",
          status: 500,
          details: error,
        };
      }
    }
  },
  login: async (email, password, captchaToken) => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        email,
        password,
        captchaToken,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  getRoleWithUsers: async (selectedPage, limit) => {
    try {
      const response = await axios.get(
        `${API_URL}/roles-with-users/${selectedPage}`,
        {
          params: { limit },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error obteniendo los roles con sus usuarios:", error);
      throw error.response?.data || error.message;
    }
  },
  getUsersByRole: async (roleId, page, limit, search) => {
    if (!roleId) throw new Error("Rol no proporcionado");
    const response = await axios.get(`${API_URL}/${roleId}/users`, {
      params: {
        page,
        limit,
        search,
      },
    });
    return response.data;
    // { success: true, data, total, currentPage, totalPages }
  },
  getUser: async (id) => {
    if (!id) throw new Error("Usuario no proporcionado");
    const response = await axios.get(`${API_URL}/user/${id}`, {});
    return response.data;
  },
  // método para obtener usuarios con filtros
  getUsersWithRol: async (
    selectedPage,
    usersPerPage,
    searchTerm,
    role,
    filterIds = []
  ) => {
    try {
      const params = {
        usersPerPage,
        selectedPage,
        searchTerm,
      };

      // Si hay IDs de filtros, los convertimos a string separado por comas
      if (filterIds && filterIds.length > 0) {
        params.filters = filterIds.join(",");
      }

      const response = await axios.get(`${API_URL}/${role}/users`, {
        params,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error obteniendo los usuarios con rol:", error);
      throw error.response?.data || error.message;
    }
  },

  // servicio para obtener filtros por rol
  getFiltersByRoleId: async (roleId) => {
    try {
      const response = await axios.get(`${API_URL}/filters/role/${roleId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error obteniendo filtros por rol:", error);
      throw error.response?.data || error.message;
    }
  },

  // Asignar filtro a usuario
  assignFilterToUser: async (userId, filterId) => {
    try {
      const response = await axios.post(
        `${API_URL}/filters/user`,
        { userId, filterId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error asignando filtro a usuario:", error);
      throw error.response?.data || error.message;
    }
  },

  // Remover filtro de usuario
  removeFilterFromUser: async (userId, filterId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/filters/user/${userId}/${filterId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error removiendo filtro de usuario:", error);
      throw error.response?.data || error.message;
    }
  },
  // service/userService.js (método adicional)
getUsersByRoleWithoutPagination: async (roleId) => {
  try {
    const response = await axios.get(
      `${API_URL}/${roleId}/users/all`,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error obteniendo todos los usuarios del rol:', error);
    throw error.response?.data || error.message;
  }
}
};
export const mediaService = {
  getMediaWithUrl: async (url, type) => {
    try {
      const response = await axios.get(
        `${API_URL}/media-document-stream-url/${url}`,
        {
          params: { type },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error obteniendo los roles con sus usuarios:", error);
      throw error.response?.data || error.message;
    }
  },
  getVehicleMediaWithUrl: async (vehicleId, type) => {
    try {
      const response = await axios.get(
        `${API_URL}/vehicle-media-stream/${vehicleId}`,
        {
          params: { type },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error obteniendo media del vehículo:", error);
      throw error.response?.data || error.message;
    }
  },
  // Devuelve el contenido base64 y metadatos de un archivo (imagen/video/PDF)
  getMediaByFileId: async (fileId) => {
    try {
      const response = await axios.get(
        `${API_URL}/media-document-stream/${fileId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data; // { content, fileName, fileType, fileSize }
    } catch (error) {
      console.error("Error obteniendo el archivo por fileId:", error);
      throw error.response?.data || error.message;
    }
  },

  downloadDocument: async (fileId) => {
    try {
      // GET a la ruta /download-document/:fileId
      const response = await axios.get(
        `${API_URL}/download-document/${fileId}`,
        {
          responseType: "blob", // Para manejar la descarga binaria
        }
      );
      // Construimos un enlace temporal para forzar la descarga
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      // Si tu backend retorna nombre original, podrías usarlo. Por ahora, uno genérico:
      link.setAttribute("download", `document_${fileId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar documento:", error);
      throw error;
    }
  },
  downloadMultipleDocuments: async (fileIds, typeData, signal) => {
    console.log(typeData);
    try {
      const response = await axios.post(
        `${API_URL}/download-multiple-documents`,
        { fileIds, typeData },
        {
          responseType: "blob",
          signal,
          headers: {
            Accept: "application/pdf",
          },
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // Opcional: actualizar progreso
          },
        }
      );

      // Verificar que la respuesta sea válida
      if (!(response.data instanceof Blob)) {
        throw new Error("Respuesta inválida del servidor");
      }

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      // Verificar que el blob sea válido
      if (blob.size === 0) {
        throw new Error("PDF generado está vacío");
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `documentos_${Date.now()}.pdf`);

      // Usar timeout para asegurar que el blob se procese
      setTimeout(() => {
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }, 100);

      return { success: true };
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Solicitud cancelada");
        throw new Error("Descarga cancelada por el usuario");
      }
      console.error("Error en la descarga:", error);
      throw error;
    }
  },

  // Actualizar documento (sustituir archivo)
  updateDocument: async (documentId, documentData) => {
    try {
      // Procesamiento de archivo a Base64
      const processFileToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      // Normalizar la estructura del documento
      const prepareDocumentPayload = async (doc) => {
        let payload = {
          documentTypeId: doc.docType,
          originalNameDescription: doc.description || "",
          required: Boolean(doc.required),
          expeditionDate: doc.expeditionDate || null,
          expirationDate: doc.expirationDate || null,
          userId: doc.userId,
        };

        // Si hay un nuevo archivo, procesarlo
        if (doc.file && doc.file instanceof File) {
          const base64Content = await processFileToBase64(doc.file);
          payload = {
            ...payload,
            fileName: doc.fileName,
            originalName: doc.file.name,
            fileType: doc.file.type,
            fileSize: doc.file.size,
            content: base64Content,
          };
        }

        return payload;
      };

      // Preparar el payload completo
      const payload = await prepareDocumentPayload(documentData);

      // Realizar la petición al backend
      const response = await axios.put(
        `${API_URL}/update-document/${documentId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error en updateDocument:", error);
      throw error.response?.data || error;
    }
  },
  createDocument: async (documentData) => {
    try {
      // Procesamiento de archivo a Base64
      const processFileToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      // Normalizar la estructura del documento
      const prepareDocumentPayload = async (doc) => {
        // Verificación de archivo
        if (!doc.file || !(doc.file instanceof File)) {
          throw new Error("Archivo inválido o no seleccionado");
        }

        // Convertir archivo a base64
        const base64Content = await processFileToBase64(doc.file);

        return {
          documentTypeId: doc.docType,
          fileName: doc.fileName,
          originalName: doc.file.name,
          fileType: doc.file.type,
          fileSize: doc.file.size,
          content: base64Content,
          expeditionDate: doc.expeditionDate || null,
          expirationDate: doc.expirationDate || null,
          originalNameDescription: doc.description || "",
          required: Boolean(doc.required),
          userId: doc.userId,
        };
      };

      // Preparar el payload completo
      const payload = await prepareDocumentPayload(documentData);

      // Realizar la petición al backend
      const response = await axios.post(
        `${API_URL}/create-documents`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error en createDocument:", error);
      throw error.response?.data || error;
    }
  },

  // Eliminar
  deleteDocument: async (fileId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/delete-document/${fileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al eliminar documento:", error);
      throw error;
    }
  },
};
export const rolService = {
  //necesito actulizar este:
  getRolService: async () => {
    try {
      const response = await axios.get(`${API_URL}/role`, {
        //params: "",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error obteniendo los roles con sus usuarios:", error);
      throw error.response?.data || error.message;
    }
  },
};
export const filterService = {
  // Obtener todos los filtros
  getAllFilters: async () => {
    try {
      const response = await axios.get(`${API_URL}/filters`);
      return response.data;
    } catch (error) {
      console.error('Error obteniendo los filtros:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Obtener filtros por rol
  getFiltersByRoleId: async (roleId) => {
    try {
      const response = await axios.get(`${API_URL}/filters/role/${roleId}`);
      return response.data;
    } catch (error) {
      console.error('Error obteniendo filtros por rol:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Crear un nuevo filtro
  createFilter: async (filterData) => {
    try {
      const response = await axios.post(`${API_URL}/filters`, filterData);
      return response.data;
    } catch (error) {
      console.error('Error creando filtro:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Crear relación entre filtros (padre-hijo)
  createFilterRelationship: async (parentId, childId) => {
    try {
      const response = await axios.post(
        `${API_URL}/filters/relationship`,
        { parentId, childId }
      );
      return response.data;
    } catch (error) {
      console.error('Error creando relación de filtros:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Asignar filtro a un rol
  assignFilterToRole: async (roleId, filterId) => {
    try {
      const response = await axios.post(
        `${API_URL}/filters/role`,
        { roleId, filterId }
      );
      return response.data;
    } catch (error) {
      console.error('Error asignando filtro a rol:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Remover filtro de un rol
  removeFilterFromRole: async (roleId, filterId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/filters/role/${roleId}/${filterId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error removiendo filtro de rol:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Asignar filtro a usuario
  assignFilterToUser: async (userId, filterId) => {
    try {
      const response = await axios.post(
        `${API_URL}/filters/user`,
        { userId, filterId }
      );
      return response.data;
    } catch (error) {
      console.error('Error asignando filtro a usuario:', error);
      throw error.response?.data || error.message;
    }
  },
  
  // Remover filtro de usuario
  removeFilterFromUser: async (userId, filterId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/filters/user/${userId}/${filterId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error removiendo filtro de usuario:', error);
      throw error.response?.data || error.message;
    }
  },
  // Actualizar un filtro
updateFilter: async (filterId, filterData) => {
  try {
    const response = await axios.put(
      `${API_URL}/filters/${filterId}`, 
      filterData
    );
    return response.data;
  } catch (error) {
    console.error('Error actualizando filtro:', error);
    throw error.response?.data || error.message;
  }
},

// Eliminar un filtro
deleteFilter: async (filterId) => {
  try {
    const response = await axios.delete(`${API_URL}/filters/${filterId}`);
    return response.data;
  } catch (error) {
    console.error('Error eliminando filtro:', error);
    throw error.response?.data || error.message;
  }
},
removeFilterRelationship: async (parentId, childId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/filters/relationship/${parentId}/${childId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error eliminando relación de filtros:', error);
    throw error.response?.data || error.message;
  }
},

// Actualizar posición de un filtro
updateFilterPosition: async (filterId, position, targetCategory) => {
  try {
    const response = await axios.put(
      `${API_URL}/filters/${filterId}/position`,
      { position, targetCategory }
    );
    return response.data;
  } catch (error) {
    console.error('Error actualizando posición:', error);
    throw error.response?.data || error.message;
  }
},

// Cambiar categoría de un filtro
changeFilterCategory: async (filterId, category, position = 0) => {
  try {
    const response = await axios.put(
      `${API_URL}/filters/${filterId}/category`,
      { category, position }
    );
    return response.data;
  } catch (error) {
    console.error('Error cambiando categoría:', error);
    throw error.response?.data || error.message;
  }
}
};


// Función de utilidad para validar el formato de fecha
function isValidDateFormat(dateString) {
  // Expresión regular para validar formato YYYY-MM-DD
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateFormatRegex.test(dateString)) {
    return false;
  }

  // Validación adicional de fecha válida
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
}
