// components/maps/RouteRenderer.jsx
import React, { useEffect, useState } from 'react';
import { Polyline, Marker } from '@react-google-maps/api';

const RouteRenderer = ({ trip, googleMapsPolyline, actualPath }) => {
  const [optimizedPath, setOptimizedPath] = useState([]);
  const [actualPolyline, setActualPolyline] = useState([]);
  
  useEffect(() => {
    // Procesar polyline de Google Maps si está disponible
    if (googleMapsPolyline) {
      try {
        const decodedPath = window.google.maps.geometry.encoding.decodePath(googleMapsPolyline);
        setOptimizedPath(decodedPath);
        console.log("Polyline de Google Maps decodificado:", decodedPath.length, "puntos");
      } catch (error) {
        console.error("Error al decodificar polyline:", error);
        setOptimizedPath([]);
      }
    } else {
      console.log("No hay polyline de Google Maps disponible");
      setOptimizedPath([]);
    }
    
    // Crear polyline de la ruta real a partir de registros GPS
    if (actualPath && actualPath.length) {
      try {
        const validPoints = actualPath.filter(p => 
          p.latitud && p.longitud && 
          !isNaN(parseFloat(p.latitud)) && 
          !isNaN(parseFloat(p.longitud))
        );
        
        console.log("Procesando ruta GPS con", validPoints.length, "puntos válidos de", actualPath.length, "totales");
        
        if (validPoints.length === 0) {
          console.warn("No se encontraron puntos GPS válidos para la ruta");
          setActualPolyline([]);
          return;
        }
        
        const pathPoints = validPoints.map(p => ({
          lat: parseFloat(p.latitud),
          lng: parseFloat(p.longitud)
        }));
        
        setActualPolyline(pathPoints);
      } catch (error) {
        console.error("Error procesando ruta GPS:", error);
        setActualPolyline([]);
      }
    } else {
      console.log("No hay registros GPS para la ruta");
      setActualPolyline([]);
    }
  }, [googleMapsPolyline, actualPath, trip]);

  // Verificar que tengamos coordenadas válidas para los marcadores
  const hasValidOrigin = trip && trip.origin && 
                        !isNaN(parseFloat(trip.origin.lat)) && 
                        !isNaN(parseFloat(trip.origin.lon));
                        
  const hasValidDestination = trip && trip.destination && 
                             !isNaN(parseFloat(trip.destination.lat)) && 
                             !isNaN(parseFloat(trip.destination.lon));

  return (
    <>
      {/* Ruta de Google Maps (línea azul) */}
      {optimizedPath.length > 0 && (
        <Polyline
          path={optimizedPath}
          options={{
            strokeColor: "#2979FF",
            strokeOpacity: 0.8,
            strokeWeight: 5,
            zIndex: 1
          }}
        />
      )}
      
      {/* Ruta real recorrida (línea naranja) */}
      {actualPolyline.length > 0 && (
        <Polyline
          path={actualPolyline}
          options={{
            strokeColor: "#FF6D00",
            strokeOpacity: 0.7,
            strokeWeight: 3,
            zIndex: 2
          }}
        />
      )}
      
      {/* Marcador de origen */}
      {hasValidOrigin && (
        <Marker
          position={{ lat: parseFloat(trip.origin.lat), lng: parseFloat(trip.origin.lon) }}
          icon={{
            url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24' fill='%234CAF50' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M12 8v8M8 12h8'/%3E%3C/svg%3E",
            scaledSize: new window.google.maps.Size(36, 36),
            anchor: new window.google.maps.Point(18, 18)
          }}
          label={{
            text: "Inicio",
            color: "#FFFFFF",
            fontWeight: "bold",
            className: "marker-label origin-label"
          }}
        />
      )}
      
      {/* Marcador de destino */}
      {hasValidDestination && (
        <Marker
          position={{ lat: parseFloat(trip.destination.lat), lng: parseFloat(trip.destination.lon) }}
          icon={{
            url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24' fill='%23F44336' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M12 8v8'/%3E%3C/svg%3E",
            scaledSize: new window.google.maps.Size(36, 36),
            anchor: new window.google.maps.Point(18, 18)
          }}
          label={{
            text: "Fin",
            color: "#FFFFFF",
            fontWeight: "bold",
            className: "marker-label destination-label"
          }}
        />
      )}
    </>
  );
};

export default RouteRenderer;