import PruebaIcon from "../assets/svg/layout/prueba.svg";
import DashboardIcon from "../assets/svg/layout/dashboard.svg";
import DashboardLightIcon from "../assets/svg/layout/dashboard_light.svg";
import CarIcon from "../assets/svg/layout/car.svg";
import CarLightIcon from "../assets/svg/layout/car_light.svg";
import MessageIcon from "../assets/svg/layout/message.svg";
import MessageLightIcon from "../assets/svg/layout/message_light.svg";
import PeopleIcon from "../assets/svg/layout/people.svg";
import PeopleLightIcon from "../assets/svg/layout/people_light.svg";
import PointIcon from "../assets/svg/layout/point.svg";
import PointLightIcon from "../assets/svg/layout/point_light.svg";
import ReturnIcon from "../assets/svg/layout/return.svg";
import ReturnLightIcon from "../assets/svg/layout/return_light.svg";
import TrendIcon from "../assets/svg/layout/trend.svg";
import TrendLightIcon from "../assets/svg/layout/trend_light.svg";
import PanelLeftIcon from "../assets/svg/layout/panel_left.svg";
import PanelLeftLightIcon from "../assets/svg/layout/panel_left_light.svg";
import PanelRightIcon from "../assets/svg/layout/panel_right.svg";
import PanelRightLightIcon from "../assets/svg/layout/panel_right_light.svg";
import FlagIcon from "../assets/svg/layout/flag.svg";
import FlagLightIcon from "../assets/svg/layout/flag_light.svg";
import OffIcon from "../assets/svg/layout/off.svg";
import OffLightIcon from "../assets/svg/layout/off_light.svg";
import DownloadIconMini from "../assets/svg/layout/download_mini.svg";
import DownloadIconMiniLight from "../assets/svg/layout/download_mini_light.svg";
import ClockIcon from "../assets/svg/layout/clock.svg";
import ClockLightIcon from "../assets/svg/layout/clock_light.svg";
import TravelIcon from "../assets/svg/layout/travel.svg";
import TravelIconLight from "../assets/svg/layout/travel_light.svg";
import FolderIcon from "../assets/svg/components/folder_dark.svg";
import FolderIconLight from "../assets/svg/components/folder_white.svg";

export const logoutRoute = {
  path: "/",
  name: "Cerrar Sesión",
  icon: OffIcon,
  iconLight: OffLightIcon
};

export const hidePanelRoute = {
  name: "Ocultar panel",
  icon: PanelLeftIcon,
  iconLight: PanelLeftLightIcon
};

export const showPanelRoute = {
  name: "Mostrar panel",
  icon: PanelRightIcon,
  iconLight: PanelRightLightIcon
};

export const helpRoute = {
  path: "/help",
  name: "Ayuda",
  icon: FlagIcon,
  iconLight: FlagLightIcon
};

export const menuRoutes = {
  Admin: [
    // Rutas principales que quedan en el primer nivel
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      iconLight: DashboardLightIcon
    },
    {
      path: "/satellite-uploader",
      name: "Añadir reportes",
      icon: DownloadIconMini,
      iconLight: DownloadIconMiniLight
    },
    {
      path: "/geofences-travel-and-more",
      name: "Rutas de viajes",
      icon: TravelIcon,
      iconLight: TravelIconLight
    },
    
    // Carpeta de Recursos Humanos
    {
      name: "Recursos Humanos",
      icon: FolderIcon,
      iconLight: FolderIconLight,
      isFolder: true,
      children: [
        {
          path: "/alerts-persons",
          name: "Alertas",
          icon: ClockIcon,
          iconLight: ClockLightIcon
        },
        {
          path: "/persons",
          name: "Personas",
          icon: PeopleIcon,
          iconLight: PeopleLightIcon
        }
      ]
    },
    
    // Carpeta de Vehículos
    {
      name: "Transporte",
      icon: FolderIcon,
      iconLight: FolderIconLight,
      isFolder: true,
      children: [
        {
          path: "/alerts-vehicles",
          name: "Alertas",
          icon: ClockIcon,
          iconLight: ClockLightIcon
        },
        {
          path: "/vehicles",
          name: "Vehiculos",
          icon: CarIcon,
          iconLight: CarLightIcon
        }

      ]
    },
    
    // Carpeta de Monitoreo
    {
      name: "Monitoreo",
      icon: FolderIcon,
      iconLight: FolderIconLight,
      isFolder: true,
      children: [
        {
          path: "/trends",
          name: "Tendencias",
          icon: TrendIcon,
          iconLight: TrendLightIcon
        },
        {
          path: "/hotspots",
          name: "Puntos Criticos",
          icon: PointIcon,
          iconLight: PointLightIcon
        },
        {
          path: "/repeat-offenders",
          name: "Reincidentes",
          icon: ReturnIcon,
          iconLight: ReturnLightIcon
        },
        {
          path: "/transport-calculator",
          name: "Calcular trasporte",
          icon: ReturnIcon,
          iconLight: ReturnLightIcon
        }
      ]
    }
  ],
  Vehiculos: [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      iconLight: DashboardLightIcon
    },
    {
      path: "/mis-vehiculos",
      name: "Mis Vehículos",
      icon: CarIcon,
      iconLight: CarLightIcon
    },
    {
      path: "/carriers",
      name: "Transportistas",
      icon: PeopleIcon,
      iconLight: PeopleLightIcon
    },
    {
      path: "/mis-reportes",
      name: "Mis Reportes",
      icon: DownloadIconMini,
      iconLight: DownloadIconMiniLight
    },
    {
      path: "/perfil",
      name: "Mi Perfil",
      icon: PeopleIcon,
      iconLight: PeopleLightIcon
    },
  ],
  Terceros: [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      iconLight: DashboardLightIcon
    },
    {
      path: "/reportes-asignados",
      name: "Reportes Asignados",
      icon: DownloadIconMini,
      iconLight: DownloadIconMiniLight
    },
    {
      path: "/perfil",
      name: "Mi Perfil",
      icon: PeopleIcon,
      iconLight: PeopleLightIcon
    },
  ],
  RRHH: [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      iconLight: DashboardLightIcon
    },
    {
      path: "/persons",
      name: "Personas",
      icon: PeopleIcon,
      iconLight: PeopleLightIcon
    },
    {
      path: "/geofences-travel-and-more",
      name: "Rutas de viajes",
      icon: TravelIcon,
      iconLight: TravelIconLight
    },
    {
      path: "/message",
      name: "Mensajes",
      icon: MessageIcon,
      iconLight: MessageLightIcon
    },
  ],
};