import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Country, State, City } from "country-state-city";
import "../assets/scss/components/ui/dropdawn/LocationSelector.scss";
import { useLayout } from "../context/LayoutContext";

export default function LocationSelector({
  onLocationSelect,
  locationData = null,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [addressDetails, setAddressDetails] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locationStatus, setLocationStatus] = useState("");
  const { theme } = useLayout();

  // Estados para controlar los dropdowns
  const [countrySearch, setCountrySearch] = useState("");
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [departmentDropdownOpen, setDepartmentDropdownOpen] = useState(false);
  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);

  // Referencias para cerrar dropdowns al hacer clic fuera
  const countryRef = useRef(null);
  const departmentRef = useRef(null);
  const cityRef = useRef(null);

  // Se obtienen todos los países
  const allCountries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  // Filtrar países según la búsqueda
  const filteredCountries = allCountries.filter(country => 
    country.label.toLowerCase().includes(countrySearch.toLowerCase())
  );

  // Estados/Departamentos según el país seleccionado
  const allDepartments = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map((dept) => ({
        value: dept.isoCode,
        label: dept.name,
      }))
    : [];

  // Filtrar departamentos según la búsqueda
  const filteredDepartments = allDepartments.filter(dept => 
    dept.label.toLowerCase().includes(departmentSearch.toLowerCase())
  );

  // Ciudades según el Estado/Departamento seleccionado
  const allCities = selectedCountry && selectedDepartment
    ? City.getCitiesOfState(
        selectedCountry.value,
        selectedDepartment.value
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }))
    : [];

  // Filtrar ciudades según la búsqueda
  const filteredCities = allCities.filter(city => 
    city.label.toLowerCase().includes(citySearch.toLowerCase())
  );

  // Manejador para cerrar dropdowns al hacer clic fuera
  useEffect(() => {
    function handleClickOutside(event) {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        setCountryDropdownOpen(false);
      }
      if (departmentRef.current && !departmentRef.current.contains(event.target)) {
        setDepartmentDropdownOpen(false);
      }
      if (cityRef.current && !cityRef.current.contains(event.target)) {
        setCityDropdownOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Solo actualizar si hay locationData y no estamos en modo edición activa
    if (locationData && !isEditing) {
      try {
        // Función auxiliar para encontrar coincidencia exacta o parcial
        const findMatch = (array, searchTerm) => {
          const exactMatch = array.find(item => item.label.toLowerCase() === searchTerm?.label?.toLowerCase());
          if (exactMatch) return exactMatch;
          
          const partialMatch = array.find(item => 
            item.label.toLowerCase().includes(searchTerm?.label?.toLowerCase())
          );
          return partialMatch || null;
        };
  
        // Buscar y establecer país
        const country = findMatch(allCountries, locationData.country);
        if (!country) throw new Error('País no encontrado');
        setSelectedCountry(country);
  
        // Obtener y mapear departamentos del país seleccionado
        const availableDepartments = State.getStatesOfCountry(country.value).map(dept => ({
          value: dept.isoCode,
          label: dept.name,
        }));
  
        // Buscar y establecer departamento
        const department = findMatch(availableDepartments, locationData.department);
        if (!department) throw new Error('Departamento no encontrado');
        setSelectedDepartment(department);
  
        // Obtener y mapear ciudades del departamento seleccionado
        const availableCities = City.getCitiesOfState(
          country.value,
          department.value
        ).map(city => ({
          value: city.name,
          label: city.name,
        }));
  
        // Buscar y establecer ciudad
        const city = findMatch(availableCities, locationData.city);
        if (!city) throw new Error('Ciudad no encontrada');
        setSelectedCity(city);
  
        // Establecer detalles adicionales
        setAddressDetails(locationData.addressDetails?.trim() || '');
        setPostalCode(locationData.postalCode?.trim() || '');
        
        // Marcar como cargado exitosamente
        setLocationStatus('uploaded');
        setIsEditing(true);
      } catch (error) {
        console.warn('Error al cargar ubicación:', error.message);
        // Restablecer estados en caso de error
        setSelectedCountry(null);
        setSelectedDepartment(null);
        setSelectedCity(null);
        setAddressDetails('');
        setPostalCode('');
        setLocationStatus('not-uploaded');
      }
    }
  }, [locationData, allCountries, isEditing]);

  // Selección de país
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setSelectedDepartment(null);
    setSelectedCity(null);
    setCountrySearch("");
    setCountryDropdownOpen(false);
  };

  // Selección de departamento
  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setSelectedCity(null);
    setDepartmentSearch("");
    setDepartmentDropdownOpen(false);
  };

  // Selección de ciudad
  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCitySearch("");
    setCityDropdownOpen(false);
  };

  // Función para truncar la dirección (solo para mostrar en el status)
  const truncateAddress = (address) => {
    return address.length > 15 ? `${address.substring(0, 15)}...` : address;
  };

  // Renderiza el estado de la ubicación abajo del botón
  const renderLocationStatus = () => {
    if (!locationStatus) return null;

    if (locationStatus === "uploaded") {
      const uploadedAddress = truncateAddress(addressDetails);
      return (
        <p className={`GM__${theme}-status-message GM__${theme}-status-uploaded`}>
          Dirección subida: {uploadedAddress}
        </p>
      );
    }

    if (locationStatus === "not-uploaded") {
      return (
        <p className={`GM__${theme}-status-message GM__${theme}-status-error`}>
          No se ha subido la dirección
        </p>
      );
    }
  };

  // Manejo de envío de ubicación
  const handleSubmit = () => {
    const dataToSend = {
      country: selectedCountry,
      department: selectedDepartment,
      city: selectedCity,
      addressDetails,
      postalCode,
    };

    if (selectedCountry && selectedDepartment && selectedCity) {
      onLocationSelect(dataToSend);
      setLocationStatus("uploaded");
      setIsEditing(false); 
    } else {
      console.warn("Por favor complete todos los campos de ubicación");
      setLocationStatus("not-uploaded");
    }
  };

  return (
    <div className={`GM__${theme}-location-selector`}>
      <div className={`GM__${theme}-section-title`}>
        <h3>Ubicación</h3>
      </div>
      
      {/* Selector de País con búsqueda integrada */}
      <div className={`GM__${theme}-location-row`}>
        <div className={`GM__${theme}-field-group`} ref={countryRef}>
          <label htmlFor="country-selector">País</label>
          <div className={`GM__${theme}-searchable-dropdown`}>
            <div 
              className={`GM__${theme}-dropdown-selected ${countryDropdownOpen ? 'active' : ''}`}
              onClick={() => setCountryDropdownOpen(!countryDropdownOpen)}
            >
              {selectedCountry ? selectedCountry.label : "-- Seleccione un país --"}
              <span className={`GM__${theme}-dropdown-arrow`}></span>
            </div>
            
            {countryDropdownOpen && (
              <div className={`GM__${theme}-dropdown-menu`}>
                <div className={`GM__${theme}-dropdown-search`}>
                  <input
                    type="text"
                    value={countrySearch}
                    onChange={(e) => setCountrySearch(e.target.value)}
                    placeholder="Buscar país..."
                    onClick={(e) => e.stopPropagation()}
                    autoFocus
                  />
                </div>
                <div className={`GM__${theme}-dropdown-options`}>
                  {filteredCountries.length > 0 ? (
                    filteredCountries.map((country) => (
                      <div
                        key={country.value}
                        className={`GM__${theme}-dropdown-option ${
                          selectedCountry?.value === country.value ? 'selected' : ''
                        }`}
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country.label}
                      </div>
                    ))
                  ) : (
                    <div className={`GM__${theme}-dropdown-no-results`}>
                      No se encontraron resultados
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Selector de Departamento con búsqueda integrada */}
      {selectedCountry && (
        <div className={`GM__${theme}-location-row`}>
          <div className={`GM__${theme}-field-group`} ref={departmentRef}>
            <label htmlFor="department-selector">Departamento / Estado</label>
            <div className={`GM__${theme}-searchable-dropdown`}>
              <div 
                className={`GM__${theme}-dropdown-selected ${departmentDropdownOpen ? 'active' : ''}`}
                onClick={() => setDepartmentDropdownOpen(!departmentDropdownOpen)}
              >
                {selectedDepartment ? selectedDepartment.label : "-- Seleccione un departamento/estado --"}
                <span className={`GM__${theme}-dropdown-arrow`}></span>
              </div>
              
              {departmentDropdownOpen && (
                <div className={`GM__${theme}-dropdown-menu`}>
                  <div className={`GM__${theme}-dropdown-search`}>
                    <input
                      type="text"
                      value={departmentSearch}
                      onChange={(e) => setDepartmentSearch(e.target.value)}
                      placeholder="Buscar departamento..."
                      onClick={(e) => e.stopPropagation()}
                      autoFocus
                    />
                  </div>
                  <div className={`GM__${theme}-dropdown-options`}>
                    {filteredDepartments.length > 0 ? (
                      filteredDepartments.map((department) => (
                        <div
                          key={department.value}
                          className={`GM__${theme}-dropdown-option ${
                            selectedDepartment?.value === department.value ? 'selected' : ''
                          }`}
                          onClick={() => handleDepartmentSelect(department)}
                        >
                          {department.label}
                        </div>
                      ))
                    ) : (
                      <div className={`GM__${theme}-dropdown-no-results`}>
                        No se encontraron resultados
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      
      {/* Selector de Ciudad con búsqueda integrada */}
      {selectedDepartment && (
        <div className={`GM__${theme}-location-row`}>
          <div className={`GM__${theme}-field-group`} ref={cityRef}>
            <label htmlFor="city-selector">Ciudad</label>
            <div className={`GM__${theme}-searchable-dropdown`}>
              <div 
                className={`GM__${theme}-dropdown-selected ${cityDropdownOpen ? 'active' : ''}`}
                onClick={() => setCityDropdownOpen(!cityDropdownOpen)}
              >
                {selectedCity ? selectedCity.label : "-- Seleccione una ciudad --"}
                <span className={`GM__${theme}-dropdown-arrow`}></span>
              </div>
              
              {cityDropdownOpen && (
                <div className={`GM__${theme}-dropdown-menu`}>
                  <div className={`GM__${theme}-dropdown-search`}>
                    <input
                      type="text"
                      value={citySearch}
                      onChange={(e) => setCitySearch(e.target.value)}
                      placeholder="Buscar ciudad..."
                      onClick={(e) => e.stopPropagation()}
                      autoFocus
                    />
                  </div>
                  <div className={`GM__${theme}-dropdown-options`}>
                    {filteredCities.length > 0 ? (
                      filteredCities.map((city) => (
                        <div
                          key={city.value}
                          className={`GM__${theme}-dropdown-option ${
                            selectedCity?.value === city.value ? 'selected' : ''
                          }`}
                          onClick={() => handleCitySelect(city)}
                        >
                          {city.label}
                        </div>
                      ))
                    ) : (
                      <div className={`GM__${theme}-dropdown-no-results`}>
                        No se encontraron resultados
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Fila 4: Dirección y Código Postal */}
      <div className={`GM__${theme}-location-row GM__${theme}-address-row`}>
        {/* Dirección (campo grande) */}
        <div className={`GM__${theme}-field-group GM__${theme}-field-address`}>
          <label htmlFor="addressDetails">Dirección</label>
          <textarea
            id="addressDetails"
            value={addressDetails}
            onChange={(e) => setAddressDetails(e.target.value)}
            className={`GM__${theme}-textarea`}
            rows="2"
          />
        </div>

        {/* Código Postal (campo más pequeño) */}
        <div className={`GM__${theme}-field-group GM__${theme}-field-postal`}>
          <label htmlFor="postalCode">Código Postal</label>
          <input
            id="postalCode"
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            className={`GM__${theme}-input`}
          />
        </div>
      </div>
      
      {/* Fila 5: Botón y Estado */}
      <div className={`GM__${theme}-location-row GM__${theme}-button-row`}>
        <button
          className={`GM__${theme}-save-button`}
          onClick={handleSubmit}
          disabled={!selectedCountry || !selectedDepartment || !selectedCity}
        >
          Guardar Ubicación
        </button>
        {renderLocationStatus()}
      </div>
    </div>
  );
}

LocationSelector.propTypes = {
  onLocationSelect: PropTypes.func.isRequired,
  locationData: PropTypes.shape({
    country: PropTypes.object,
    department: PropTypes.object,
    city: PropTypes.object,
    addressDetails: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};