import React, { useEffect, useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

const EventMarkers = ({ trip, filters }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState({
    speedViolations: [],
    criticalPoints: [],
    harshAcceleration: [],
    harshBraking: [],

  });
  

  useEffect(() => {
    if (!trip) return;
    
    // Procesar excesos de velocidad con validación segura
    const speedViolations = Array.isArray(trip.overspeedEvents) 
      ? trip.overspeedEvents.map((event, idx) => ({
          id: `speed-${idx}`,
          position: { lat: event.lat, lng: event.lon },
          type: 'speed',
          details: {
            velocidad: event.speed,
            hora: event.dateTime?.split(' ')[1] || 'N/A',
            fecha: event.dateTime?.split(' ')[0] || 'N/A',
            direccion: event.direccion || 'No disponible'
          }
        })) 
      : [];
    
    // Procesar violaciones a puntos críticos con validación segura
    const criticalPoints = Array.isArray(trip.criticalViolations) 
      ? trip.criticalViolations.map((violation, idx) => ({
          id: `critical-${idx}`,
          position: { lat: violation.lat, lng: violation.lon },
          type: 'critical',
          details: {
            velocidad: violation.speed,
            hora: violation.dateTime?.split(' ')[1] || 'N/A',
            fecha: violation.dateTime?.split(' ')[0] || 'N/A',
            puntoCritico: violation.pointName || 'No especificado'
          }
        })) 
      : [];
    
    // Procesar aceleraciones bruscas con validación segura
    const harshAcceleration = Array.isArray(trip.harshAccelerationEvents) 
      ? trip.harshAccelerationEvents.map((event, idx) => ({
          id: `accel-${idx}`,
          position: { lat: event.lat, lng: event.lon },
          type: 'acceleration',
          details: {
            velocidad: event.speed,
            hora: event.dateTime?.split(' ')[1] || 'N/A',
            fecha: event.dateTime?.split(' ')[0] || 'N/A',
            direccion: event.direccion || 'No disponible'
          }
        })) 
      : [];
    
    // Procesar frenadas bruscas con validación segura
    const harshBraking = Array.isArray(trip.harshBrakingEvents) 
      ? trip.harshBrakingEvents.map((event, idx) => ({
          id: `brake-${idx}`,
          position: { lat: event.lat, lng: event.lon },
          type: 'braking',
          details: {
            velocidad: event.speed,
            hora: event.dateTime?.split(' ')[1] || 'N/A',
            fecha: event.dateTime?.split(' ')[0] || 'N/A',
            direccion: event.direccion || 'No disponible'
          }
        })) 
      : [];
    
    // Logging para depuración
    console.debug(`EventMarkers - Eventos procesados:`, {
      speedViolations: speedViolations.length,
      criticalPoints: criticalPoints.length,
      harshAcceleration: harshAcceleration.length,
      harshBraking: harshBraking.length
    });
    
    setMarkers({
      speedViolations,
      criticalPoints,
      harshAcceleration,
      harshBraking
    });
  }, [trip]);
  
  // Función para obtener el icono según el tipo de evento
  const getMarkerIcon = (type) => {
    switch (type) {
      case 'speed':
        return {
          url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='%23FF9800' stroke='white' stroke-width='2'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'/%3E%3Cpath d='M12 6v6l4 2'/%3E%3C/svg%3E",
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 16)
        };
      case 'critical':
        return {
          url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='%23F44336' stroke='white' stroke-width='2'%3E%3Cpath d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'/%3E%3Cline x1='12' y1='9' x2='12' y2='13'/%3E%3Cline x1='12' y1='17' x2='12.01' y2='17'/%3E%3C/svg%3E",
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 16)
        };
      case 'acceleration':
        return {
          url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='%239C27B0' stroke='white' stroke-width='2'%3E%3Cpath d='M7 17l10-10M7 7h10v10'/%3E%3C/svg%3E",
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 16)
        };
      case 'braking':
        return {
          url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='%232196F3' stroke='white' stroke-width='2'%3E%3Cpath d='M7 7l10 10M17 7l-10 0l0 10'/%3E%3C/svg%3E",
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 16)
        };
      default:
        return null;
    }
  };
  
  // Función para obtener el título según el tipo de evento
  const getMarkerTitle = (type, details) => {
    switch (type) {
      case 'speed':
        return `Exceso de velocidad: ${details.velocidad} km/h`;
      case 'critical':
        return `Violación Punto Crítico: ${details.puntoCritico}`;
      case 'acceleration':
        return 'Aceleración Brusca';
      case 'braking':
        return 'Frenada Brusca';
      default:
        return 'Evento';
    }
  };
  
  return (
    <>
      {/* Renderizar marcadores de excesos de velocidad */}
      {filters.showSpeedViolations && markers.speedViolations.map(marker => (
        <Marker
          key={marker.id}
          position={marker.position}
          icon={getMarkerIcon(marker.type)}
          title={getMarkerTitle(marker.type, marker.details)}
          onClick={() => setSelectedMarker(marker)}
        />
      ))}
      
      {/* Renderizar marcadores de puntos críticos */}
      {filters.showCriticalPoints && markers.criticalPoints.map(marker => (
        <Marker
          key={marker.id}
          position={marker.position}
          icon={getMarkerIcon(marker.type)}
          title={getMarkerTitle(marker.type, marker.details)}
          onClick={() => setSelectedMarker(marker)}
        />
      ))}
      
      {/* Renderizar marcadores de aceleración brusca */}
      {filters.showHarshAccel && markers.harshAcceleration.map(marker => (
        <Marker
          key={marker.id}
          position={marker.position}
          icon={getMarkerIcon(marker.type)}
          title={getMarkerTitle(marker.type, marker.details)}
          onClick={() => setSelectedMarker(marker)}
        />
      ))}
      
      {/* Renderizar marcadores de frenada brusca */}
      {filters.showHarshBraking && markers.harshBraking.map(marker => (
        <Marker
          key={marker.id}
          position={marker.position}
          icon={getMarkerIcon(marker.type)}
          title={getMarkerTitle(marker.type, marker.details)}
          onClick={() => setSelectedMarker(marker)}
        />
      ))}
      
      {/* Ventana de información al hacer clic en un marcador */}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.position}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div className="event-info-window">
            <h3>{getMarkerTitle(selectedMarker.type, selectedMarker.details)}</h3>
            <div className="event-details">
              <p><strong>Fecha:</strong> {selectedMarker.details.fecha}</p>
              <p><strong>Hora:</strong> {selectedMarker.details.hora}</p>
              <p><strong>Velocidad:</strong> {selectedMarker.details.velocidad} km/h</p>
              {selectedMarker.type === 'critical' && (
                <p><strong>Punto Crítico:</strong> {selectedMarker.details.puntoCritico}</p>
              )}
              {(selectedMarker.type === 'speed' || selectedMarker.type === 'acceleration' || selectedMarker.type === 'braking') && (
                <p><strong>Dirección:</strong> {selectedMarker.details.direccion || 'No disponible'}</p>
              )}
              <p>
                <a 
                  href={`https://www.google.com/maps?q=${selectedMarker.position.lat},${selectedMarker.position.lng}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Ver en Google Maps
                </a>
              </p>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default EventMarkers;