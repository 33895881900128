// MapControls.jsx
import React from 'react';
import '../../assets/scss/components/maps/MapControls.scss';
import { Box, Typography, Switch, FormControlLabel, Divider, Grid } from '@mui/material';

const MapControls = ({ filters, onFilterChange }) => {
  const handleToggleFilter = (filterKey) => {
    onFilterChange({
      ...filters,
      [filterKey]: !filters[filterKey]
    });
  };

  return (
    <div className="map-controls">
      
      <Grid container spacing={2} className="map-controls-container">
        {/* Columna izquierda - Filtros */}
        <Grid item xs={12} md={6} className="filters-column">
          <Box className="filter-switches">
            <FormControlLabel
              control={
                <Switch
                  checked={filters.showSpeedViolations}
                  onChange={() => handleToggleFilter('showSpeedViolations')}
                  color="warning"
                  size="small"
                />
              }
              label="Excesos de Velocidad"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={filters.showCriticalPoints}
                  onChange={() => handleToggleFilter('showCriticalPoints')}
                  color="error"
                  size="small"
                />
              }
              label="Puntos Críticos"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={filters.showHarshAccel}
                  onChange={() => handleToggleFilter('showHarshAccel')}
                  color="secondary"
                  size="small"
                />
              }
              label="Aceleraciones Bruscas"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={filters.showHarshBraking}
                  onChange={() => handleToggleFilter('showHarshBraking')}
                  color="primary"
                  size="small"
                />
              }
              label="Frenadas Bruscas"
            />
          </Box>
        </Grid>
        
        {/* Columna derecha - Leyendas */}
        <Grid item xs={12} md={6} className="legends-column">
          <Box className="legends-container">
            <Typography variant="subtitle2" component="h4" gutterBottom>
              Leyenda de Rutas
            </Typography>
            
            <Box className="route-legend">
              <div className="legend-item">
                <div className="line-sample google-line"></div>
                <span>Ruta Google Maps (óptima)</span>
              </div>
              <div className="legend-item">
                <div className="line-sample actual-line"></div>
                <span>Ruta GPS (real recorrida)</span>
              </div>
            </Box>
            
            <Typography variant="subtitle2" component="h4" gutterBottom sx={{ mt: 2 }}>
              Leyenda de Eventos
            </Typography>
            
            <Box className="event-legend">
              <div className="legend-item">
                <div className="icon-sample speed-icon"></div>
                <span>Exceso de Velocidad</span>
              </div>
              <div className="legend-item">
                <div className="icon-sample critical-icon"></div>
                <span>Punto Crítico</span>
              </div>
              <div className="legend-item">
                <div className="icon-sample accel-icon"></div>
                <span>Aceleración Brusca</span>
              </div>
              <div className="legend-item">
                <div className="icon-sample braking-icon"></div>
                <span>Frenada Brusca</span>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MapControls;