import { createContext, useState, useContext, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useAuth } from "./AuthContext";
import { useAnnualHotspots, useMonthlyAnalytics } from "../hooks/useReactQuery";

const LayoutContext = createContext(null);

export const LayoutProvider = ({ children }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState("es");
  const [primaryColor, setPrimaryColor] = useState("#1976d2");
  const [fontSize, setFontSize] = useState("normal");
  const [headerTitle, setHeaderTitle] = useState("");
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  
  const { user } = useAuth();
  const isAuthenticated = !!user;
  
  // Nuevo estado para la fecha
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
  });

  // Función para obtener la fecha actual formateada
  const getCurrentDate = useMemo(() => {
    const date = dayjs(selectedDate).toDate();
    const options = {
      weekday: "long",
      year: "numeric", 
      month: "long",
      day: "numeric",
    };
    return date
      .toLocaleDateString("es-ES", options)
      .replace(/^./, (match) => match.toUpperCase());
  }, [selectedDate]);

  // Función para cambiar la fecha seleccionada
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Verificar si el usuario puede realizar consultas de datos
  const canFetchData = useMemo(() => {
    return !!user && (user.role_id === 3 || user.role_id === 4);
  }, [user]);


  // Consultas adicionales también controladas por autenticación
  const {
    data: monthlyAnalytics,
    isLoading,
    refetch: refetchDateData,
  } = useMonthlyAnalytics(selectedDate, canFetchData, isAuthenticated);

  const {
    data: annualHotspots,
  } = useAnnualHotspots(selectedDate, canFetchData, isAuthenticated);

  const toggleNav = useCallback(() => {
    setIsNavExpanded(!isNavExpanded);
  }, [isNavExpanded]);
  
  const toggleTheme = useCallback(() => {
    setTheme(theme === "light" ? "dark" : "light");
  }, [theme]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const changePrimaryColor = (newColor) => {
    setPrimaryColor(newColor);
  };

  const changeFontSize = (size) => {
    setFontSize(size);
  };

  // Valores del contexto actualizados
  const contextValue = useMemo(
    () => ({
      isNavExpanded,
      theme,
      language,
      primaryColor,
      fontSize,
      selectedDate,
      getCurrentDate,
      headerTitle,
      setHeaderTitle,
      isHeaderVisible,
      toggleNav,
      toggleTheme,
      changeLanguage,
      changePrimaryColor,
      changeFontSize,
      handleDateChange,
      refetchDateData,
      annualHotspots,
      isLoading,
      monthlyAnalytics,
      setIsHeaderVisible,
      canFetchData
    }),
    [
      isNavExpanded,
      theme,
      language,
      primaryColor,
      fontSize,
      selectedDate,
      getCurrentDate,
      headerTitle,
      isHeaderVisible,
      annualHotspots,
      isLoading,
      setIsHeaderVisible,
      toggleNav,
      toggleTheme,
      refetchDateData,
      monthlyAnalytics,
      canFetchData
    ]
  );

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout debe utilizarse dentro de un LayoutProvider");
  }
  return context;
};

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};