import React, { useState, useEffect } from "react";
import { 
  useGetUsersWithRole, 
  useGetFiltersByRoleId,
} from "../../../hooks/useReactQuery";
import "../../../assets/scss/views/ViewOperatorContent.scss";
import PaginationComponent from "../../../components/ui/pagination/PaginationComponent";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { debounce } from "lodash";
import { useLayout } from "../../../context/LayoutContext";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Button, Box, Typography, Card } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PublicIcon from "@mui/icons-material/Public";
import CategoryIcon from "@mui/icons-material/Category";
import FilterMenu from "../../../components/filters/FilterMenu";
import CreateFilterModal from "../../../components/filters/CreateFilterModal";
import AssignUsersToFilterModal from "../../../components/filters/AssignUsersToFilterModal";
import { useAuth } from "../../../context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import CardWithThemeUsers from "../../../components/ui/cards/CardWithThemeUsers";

export default function ViewOperatorContent() {
  const { theme } = useLayout();
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [selectedPage, setSelectedPage] = useState(1);
  const [usersPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [activeFilterIds, setActiveFilterIds] = useState({});
  const [isCreateFilterModalOpen, setIsCreateFilterModalOpen] = useState(false);
  const [isAssignUsersModalOpen, setIsAssignUsersModalOpen] = useState(false);
  const [selectedFilterInfo, setSelectedFilterInfo] = useState(null);
  
  const isAuthenticated = !!user;
  const roleId = 5; // ID del rol de operador

  // Obtener filtros dinámicos desde el backend
  const { 
    data: filtersData, 
    isLoading: filtersLoading, 
    error: filtersError 
  } = useGetFiltersByRoleId(roleId, isAuthenticated);

  // Preparar los datos de filtro para el componente FilterMenu
  const prepareFilterData = () => {
    if (!filtersData) return [];
    
    // Agrupar filtros por categoría
    const filtersByCategory = filtersData.reduce((acc, filter) => {
      if (!acc[filter.category]) {
        acc[filter.category] = [];
      }
      
      // Preparar los items del filtro y sus hijos
      const filterItem = {
        id: filter.id, // Incluimos el ID
        type: filter.children && filter.children.length > 0 ? 'folder' : 'option',
        label: filter.name,
        value: filter.value,
        icon: getIconComponent(filter.icon),
      };
      
      // Si tiene hijos, agregar items anidados
      if (filter.children && filter.children.length > 0) {
        filterItem.items = filter.children.map(child => ({
          id: child.id, // Incluimos el ID
          type: 'option',
          label: child.name,
          value: child.value,
          icon: getIconComponent(child.icon),
        }));
      }
      
      acc[filter.category].push(filterItem);
      return acc;
    }, {});
    
    // Convertir a formato esperado por el componente FilterMenu
    return Object.entries(filtersByCategory).map(([category, items]) => ({
      sectionTitle: getCategoryTitle(category),
      category,
      items,
    }));
  };
  
  // Función auxiliar para obtener el título de la categoría
  const getCategoryTitle = (category) => {
    const titles = {
      'flota': 'Por Flotas',
      'tipo': 'Por Tipo',
    };
    return titles[category] || `Por ${category.charAt(0).toUpperCase() + category.slice(1)}`;
  };
  
  // Función auxiliar para obtener componentes de iconos
  const getIconComponent = (iconName) => {
    const iconComponents = {
      'DirectionsCarIcon': <DirectionsCarIcon />,
      'LocalShippingIcon': <LocalShippingIcon />,
      'PublicIcon': <PublicIcon />,
      'CategoryIcon': <CategoryIcon />,
    };
    return iconComponents[iconName] || null;
  };

  // Usar los filtros preparados o caer en un filtro estático si hay error
  const filterData = !filtersLoading && !filtersError && filtersData 
    ? prepareFilterData() 
    : [];

  // Debounce para la búsqueda
  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
    setSelectedPage(1);
  }, 500);

  useEffect(() => {
    debouncedSearch(searchValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue]);

  // Preparar los filtros activos para enviar a la API
  const prepareFilterIdsForAPI = () => {
    // Extraer todos los IDs de filtros seleccionados
    const allFilterIds = [];
    
    // Recorrer cada categoría de filtros activos
    Object.values(activeFilterIds).forEach(categoryFilters => {
      // Si hay filtros seleccionados en esta categoría, agregar sus IDs
      if (categoryFilters && categoryFilters.length > 0) {
        allFilterIds.push(...categoryFilters);
      }
    });
    
    return allFilterIds;
  };

  const {
    data: dataUsersWithRole,
    isLoading,
    error,
  } = useGetUsersWithRole(
    selectedPage,
    usersPerPage,
    searchTerm,
    roleId,
    isAuthenticated,
    prepareFilterIdsForAPI()
  );

  const handlePageChange = (pageNumber) => {
    setSelectedPage(pageNumber);
  };

  const handleFilterChange = (category, filterId) => {
    setActiveFilterIds(prev => {
      const updatedFilters = { ...prev };
      
      // Asegurarse de que la categoría existe
      if (!updatedFilters[category]) {
        updatedFilters[category] = [];
      }
      
      // Si el ID ya está, lo quitamos
      if (updatedFilters[category].includes(filterId)) {
        updatedFilters[category] = updatedFilters[category].filter(id => id !== filterId);
      } else {
        // Si no está, lo agregamos
        updatedFilters[category] = [...updatedFilters[category], filterId];
      }
      
      return updatedFilters;
    });
    
    setSelectedPage(1);
  };

  const handleAddOperator = () => {
    navigate("persons/add/user/5");
  };

  const handleOpenCreateFilterModal = () => {
    setIsCreateFilterModalOpen(true);
  };

  const handleCloseCreateFilterModal = () => {
    setIsCreateFilterModalOpen(false);
  };

  const handleOpenAssignUsersModal = () => {
    // Encontrar la primera categoría con filtros
    const category = Object.keys(activeFilterIds).find(cat => 
      activeFilterIds[cat]?.length > 0
    );
    
    // Si hay categoría con filtros, usar el primer filtro
    if (category && activeFilterIds[category].length > 0) {
      const filterId = activeFilterIds[category][0];
      
      // Buscar el filtro en los datos (filtros principales y anidados)
      const findFilter = (id) => {
        let result = filtersData?.find(f => f.id === id || f.value === id);
        
        if (!result && filtersData) {
          // Buscar en hijos
          for (const filter of filtersData) {
            if (filter.children?.length) {
              const child = filter.children.find(c => c.id === id || c.value === id);
              if (child) return child;
            }
          }
        }
        
        return result || { id: filterId, name: `Filtro ${category}`, value: filterId };
      };
      
      setSelectedFilterInfo(findFilter(filterId));
    } else {
      // Filtro genérico si no hay seleccionados
      setSelectedFilterInfo({ id: 0, name: "Todos los operadores", value: "all" });
    }
    
    // Siempre abrir el modal
    setIsAssignUsersModalOpen(true);
  };

  const handleCloseAssignUsersModal = () => {
    setIsAssignUsersModalOpen(false);
    setSelectedFilterInfo(null);
  };

  const handleFilterCreated = () => {
    // Refrescar los datos de filtros despues de crear uno nuevo
    queryClient.invalidateQueries(["filtersByRole", roleId]);
    setIsCreateFilterModalOpen(false);
  };

  const handleUsersAssigned = () => {
    // Refrescar los datos de usuarios despues de asignar filtros
    queryClient.invalidateQueries(["usersWithRole"]);
    setIsAssignUsersModalOpen(false);
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className={`GM__${theme}-error`}>
        Error al cargar los datos: {error.message}
      </div>
    );
  }

  const totalPages = dataUsersWithRole?.totalPages || 
  Math.ceil((dataUsersWithRole?.total || 0) / usersPerPage);
  const noResults = dataUsersWithRole?.data?.length === 0;
  const hasActiveFilters = prepareFilterIdsForAPI().length > 0;

  // Obtener todos los valores de filtro activos para pasar al componente FilterMenu
  const getAllActiveFilterIds = () => {
    return Object.values(activeFilterIds).flat();
  };
  return (
    <div className={`GM__${theme}-operator-container`}>
      <div className={`GM__${theme}-operator-header`}>
        {/* Contenedor de filtros - Mejorar alineacion */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <FilterMenu
            theme={theme}
            filterData={filterData}
            onFilterChange={handleFilterChange}
            selectedFilters={getAllActiveFilterIds()}
            buttonLabel="Filtrar por"
            isLoading={filtersLoading}
          />
          
          <Tooltip title="Crear nuevo filtro" arrow placement="top">
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={handleOpenCreateFilterModal}
              className={`GM__${theme}-create-filter-button`}
            >
              Crear Filtro
            </Button>
          </Tooltip>
        </Box>

        <div className={`GM__${theme}-search-container`}>
          <div className={`GM__${theme}-search-wrapper`}>
            <input
              type="text"
              placeholder="Buscar operador (cc), por placas..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className={`GM__${theme}-search-input`}
            />
            <IconButton className={`GM__${theme}-search-button`}>
              <SearchIcon />
            </IconButton>
          </div>
        </div>

        <div className={`GM__${theme}-actions-container`}>
          <Tooltip title="Agregar nuevo operador" arrow placement="top">
            <button
              className={`GM__${theme}-add-button`}
              onClick={handleAddOperator}
              aria-label="Agregar personal"
            >
              <AddIcon />
              <span>Agregar personal</span>
            </button>
          </Tooltip>
        </div>
      </div>

      {/* Grid de operadores */}
      <div className={`GM__${theme}-operator-grid`}>
        {/* Tarjeta especial para asignar usuarios cuando no hay resultados pero hay filtros activos */}
        {noResults && hasActiveFilters && (
          <Card 
            className={`GM__${theme}-add-filter-card`} 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              padding: 3,
              height: '100%',
              minHeight: 200,
              cursor: 'pointer',
              backgroundColor: theme === 'dark' ? 'rgba(66, 66, 66, 0.8)' : 'rgba(245, 245, 245, 0.9)',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
              }
            }}
            onClick={handleOpenAssignUsersModal}
          >
            <PersonAddIcon sx={{ fontSize: 48, mb: 2, color: theme === 'dark' ? '#90caf9' : '#1976d2' }} />
            <Typography variant="h6" align="center" sx={{ mb: 1 }}>
              No se encontraron operadores para este filtro
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 2 }}>
              Haga clic para asignar operadores a este filtro
            </Typography>
            <Button 
              variant="contained" 
              startIcon={<PersonAddIcon />}
              onClick={handleOpenAssignUsersModal}
            >
              Asignar Operadores
            </Button>
          </Card>
        )}

        {/* Listar operadores si hay resultados */}
        {dataUsersWithRole?.data?.map((user) => (
          <CardWithThemeUsers
            key={user.id}
            userId={user.id}
            name={user.first_name}
            lastName={`${user.first_last_name} ${user.second_last_name}`}
            personImage={user.id}
            documentNumber={user.document_number}
            filters={user.assigned_filters || []}
            roleId={roleId}
            theme={theme}
          />
        ))}
      </div>

      {/* Paginación */}
      {(!noResults || !hasActiveFilters) && (
        <div className={`GM__${theme}-pagination-container`}>
          <PaginationComponent
            currentPage={selectedPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            theme={theme}
          />
        </div>
      )}

      {/* Modal para crear nuevos filtros */}
      <CreateFilterModal
        open={isCreateFilterModalOpen}
        onClose={handleCloseCreateFilterModal}
        onFilterCreated={handleFilterCreated}
        roleId={roleId}
        existingFilters={filtersData || []}
        theme={theme}
      />

      {/* Modal para asignar usuarios a filtros */}
      {selectedFilterInfo && (
        <AssignUsersToFilterModal
          open={isAssignUsersModalOpen}
          onClose={handleCloseAssignUsersModal}
          onUsersAssigned={handleUsersAssigned}
          filterId={selectedFilterInfo.id}
          filterName={selectedFilterInfo.name}
          roleId={roleId}
          theme={theme}
        />
      )}
    </div>
  );
}