import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "../../assets/scss/components/filters/FilterMenu.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconButton, Tooltip, TextField } from "@mui/material";
import ConfirmDialog from "../ui/modals/ConfirmDialog";
import { filterService } from "../../service";
import { useQueryClient } from "@tanstack/react-query";

// Componente para elementos tipo carpeta con posibilidad de edición
const FilterFolder = ({
  item,
  theme,
  onItemSelected,
  level = 0,
  selectedFilters,
  editMode,
  onEdit,
  onDelete,
  draggable,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(item.label);
  const isSelected = selectedFilters && selectedFilters.includes(item.value);

  const handleFolderClick = (e) => {
    if (!editMode && !isEditing) {
      e.stopPropagation();
      onItemSelected(item.value, item.category);
    }
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleEditCancel = (e) => {
    e.stopPropagation();
    setEditedName(item.label);
    setIsEditing(false);
  };

  const handleEditSave = async (e) => {
    e.stopPropagation();
    if (editedName.trim() !== "" && editedName !== item.label) {
      await onEdit(item.id, editedName);
    }
    setIsEditing(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(item.id, item.label, item.type === "folder");
  };

  return (
    <>
      <Draggable
        draggableId={`filter-${item.id}`}
        index={index}
        isDragDisabled={!draggable}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`filter-item folder-item ${theme} ${
              isSelected ? "selected" : ""
            }`}
            style={{
              paddingLeft: `${level * 8 + 16}px`,
              ...provided.draggableProps.style,
            }}
            onClick={handleFolderClick}
          >
            {draggable && (
              <div {...provided.dragHandleProps} className="drag-handle">
                <DragIndicatorIcon fontSize="small" />
              </div>
            )}

            <div className="item-content">
              {item.icon && (
                <div className="item-icon">
                  {typeof item.icon === "string" ? (
                    <img src={item.icon} alt={item.label} />
                  ) : (
                    item.icon
                  )}
                </div>
              )}

              {isEditing ? (
                <TextField
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  autoFocus
                  size="small"
                  variant="standard"
                  className="edit-field"
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                <div className="item-text">{item.label}</div>
              )}
            </div>

            <div className="folder-actions">
              <div className="action-divider"></div>

              {isEditing ? (
                <>
                  <IconButton
                    size="small"
                    onClick={handleEditSave}
                    className="action-btn save-btn"
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleEditCancel}
                    className="action-btn cancel-btn"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <>
                  {editMode && (
                    <>
                      <IconButton
                        size="small"
                        onClick={handleEditClick}
                        className="action-btn edit-btn"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={handleDeleteClick}
                        className="action-btn delete-btn"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                  <div className="settings-icon" onClick={handleExpandClick}>
                    <svg width="18" height="18" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Draggable>

      {isOpen && item.items && (
        <Droppable droppableId={`subfilters-${item.id}`} type="SUBFILTER">
          {(provided) => (
            <div
              className={`subfolder-container ${theme}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {item.items.map((subItem, idx) => (
                <FilterItem
                  key={`subitem-${subItem.id}`}
                  item={{ ...subItem, category: item.category }}
                  theme={theme}
                  onItemSelected={onItemSelected}
                  level={level + 1}
                  selectedFilters={selectedFilters}
                  editMode={editMode}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  draggable={draggable}
                  index={idx}
                  parentId={item.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </>
  );
};

// Componente para elementos tipo opción (sin expandir)
const FilterOption = ({
  item,
  theme,
  onItemSelected,
  level = 0,
  selectedFilters,
  editMode,
  onEdit,
  onDelete,
  draggable,
  index,
  parentId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(item.label);
  const isSelected = selectedFilters && selectedFilters.includes(item.value);

  const handleItemClick = (e) => {
    if (!editMode && !isEditing) {
      onItemSelected(item.value, item.category);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleEditCancel = (e) => {
    e.stopPropagation();
    setEditedName(item.label);
    setIsEditing(false);
  };

  const handleEditSave = async (e) => {
    e.stopPropagation();
    if (editedName.trim() !== "" && editedName !== item.label) {
      await onEdit(item.id, editedName);
    }
    setIsEditing(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(item.id, item.label, false);
  };

  return (
    <Draggable
      draggableId={`filter-${item.id.toString()}`}
      index={index}
      isDragDisabled={!draggable}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`filter-item option-item ${theme} ${
            isSelected ? "selected" : ""
          }`}
          style={{
            paddingLeft: `${level * 8 + 16}px`,
            ...provided.draggableProps.style,
          }}
          onClick={handleItemClick}
        >
          {draggable && (
            <div {...provided.dragHandleProps} className="drag-handle">
              <DragIndicatorIcon fontSize="small" />
            </div>
          )}

          <div className="item-content">
            {item.icon && (
              <div className="item-icon">
                {typeof item.icon === "string" ? (
                  <img src={item.icon} alt={item.label} />
                ) : (
                  item.icon
                )}
              </div>
            )}

            {isEditing ? (
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                autoFocus
                size="small"
                variant="standard"
                className="edit-field"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <div className="item-text">{item.label}</div>
            )}
          </div>

          {(editMode || isEditing) && (
            <div className="item-actions">
              {isEditing ? (
                <>
                  <IconButton
                    size="small"
                    onClick={handleEditSave}
                    className="action-btn save-btn"
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleEditCancel}
                    className="action-btn cancel-btn"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    size="small"
                    onClick={handleEditClick}
                    className="action-btn edit-btn"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleDeleteClick}
                    className="action-btn delete-btn"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

// Componente que determina si se debe renderizar una carpeta o una opcion
const FilterItem = (props) => {
  const { item, onItemSelected } = props;

  // Asegurarnos que todos los subelementos hereden la categoría del padre
  const enhancedItem = {
    ...item,
    items: item.items?.map((subItem) => ({
      ...subItem,
      category: item.category || subItem.category,
    })),
  };

  if (enhancedItem.type === "folder") {
    return <FilterFolder {...props} item={enhancedItem} />;
  } else {
    return <FilterOption {...props} item={enhancedItem} />;
  }
};

// Componente principal del menú de filtros
const FilterMenu = ({
  theme = "light",
  filterData,
  onFilterChange,
  selectedFilters = [],
  buttonLabel = "Filtrar por",
  isLoading = false,
  roleId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [dragMode, setDragMode] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });
  const [isDragging, setIsDragging] = useState(false);
  const menuRef = useRef(null);
  const queryClient = useQueryClient();

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemSelected = (value, category) => {
    onFilterChange(category, value);
    // No cerramos el menu para permitir multiples selecciones
  };

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
    if (dragMode) setDragMode(false);
  };

  const handleToggleDragMode = () => {
    setDragMode(!dragMode);
    if (editMode) setEditMode(false);
  };

  const handleEditFilter = async (filterId, newName) => {
    try {
      await filterService.updateFilter(filterId, { name: newName });
      // Refrescar los datos
      queryClient.invalidateQueries(["filtersByRole", roleId]);
    } catch (error) {
      console.error("Error al actualizar el filtro:", error);
      // Manejar error (quizás con un toast)
    }
  };

  const handleDeleteFilter = (filterId, filterName, isCategory) => {
    setConfirmDialog({
      open: true,
      title: `Eliminar ${isCategory ? "categoría" : "filtro"}`,
      message: `¿Está seguro que desea eliminar ${
        isCategory ? "la categoría" : "el filtro"
      } "${filterName}"? ${
        isCategory
          ? "Esto eliminará también todos los subfiltros asociados."
          : ""
      }`,
      onConfirm: async () => {
        try {
          await filterService.deleteFilter(filterId);
          // Refrescar los datos
          queryClient.invalidateQueries(["filtersByRole", roleId]);
        } catch (error) {
          console.error("Error al eliminar el filtro:", error);
          // Manejar error (quizás con un toast)
        }
      },
    });
  };
  const handleDragStart = () => {
    setIsDragging(true);
  };
  const handleDragEnd = async (result) => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }

    const { draggableId, source, destination, type } = result;

    // Usar un scope para evitar problemas con filterId
    try {
      const filterId = parseInt(draggableId.replace("filter-", ""));

      // Log completo para depuración
      console.log("Drag result:", {
        filterId,
        draggableId,
        sourceId: source.droppableId,
        destId: destination.droppableId,
        sourceIndex: source.index,
        destIndex: destination.index,
        type,
      });

      // Crear una promesa única para todas las operaciones
      let updatePromise;

      // Mismo contenedor (reordenamiento)
      if (source.droppableId === destination.droppableId) {
        updatePromise = filterService.updateFilterPosition(
          filterId,
          destination.index
        );
      }
      // Movimiento entre contenedores
      else {
        const isMovingToSubfilter =
          destination.droppableId.startsWith("subfilters-");
        const isMovingFromSubfilter =
          source.droppableId.startsWith("subfilters-");

        let targetCategory = null;
        let parentId = null;

        if (isMovingToSubfilter) {
          parentId = parseInt(
            destination.droppableId.replace("subfilters-", "")
          );
          const parentFilter = findFilterById(filterData, parentId);
          targetCategory = parentFilter?.category;
        } else {
          targetCategory = destination.droppableId.replace("category-", "");
        }

        // Operaciones en secuencia
        updatePromise = (async () => {
          if (isMovingToSubfilter && parentId) {
            await filterService.createFilterRelationship(parentId, filterId);
          }

          if (isMovingFromSubfilter) {
            const oldParentId = parseInt(
              source.droppableId.replace("subfilters-", "")
            );
            await filterService.removeFilterRelationship(oldParentId, filterId);
          }

          return filterService.changeFilterCategory(
            filterId,
            targetCategory,
            destination.index
          );
        })();
      }

      // IMPORTANTE: Esperar a que todas las operaciones terminen ANTES de invalidar la caché
      await updatePromise;
      // Importante: desactivar el estado de arrastre ANTES de invalidar consultas
      setIsDragging(false);

      // Sólo entonces invalidar la consulta
      queryClient.invalidateQueries(["filtersByRole", roleId]);
    } catch (error) {
      console.error("Error en operación de arrastrar y soltar:", error);
    }
  };

  // Función auxiliar para encontrar un filtro por ID
  const findFilterById = (data, id) => {
    for (const section of data) {
      for (const item of section.items) {
        if (item.id === id) return item;

        if (item.items) {
          const found = item.items.find((subItem) => subItem.id === id);
          if (found) return found;
        }
      }
    }
    return null;
  };

  // Cierra el menu cuando se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`filter-menu-container ${theme}`} ref={menuRef}>
      <button className={`filter-button ${theme}`} onClick={handleToggleMenu}>
        <FilterListIcon className="filter-icon" />
        <span>{buttonLabel}</span>
      </button>

      {isOpen && (
        <div className={`menu-dropdown ${theme}`}>
          {/* Header con controles de edición */}
          <div className={`menu-header ${theme}`}>
            <div className="header-title">Filtros</div>
            <div className="header-actions">
              <Tooltip
                title={editMode ? "Desactivar edición" : "Editar filtros"}
              >
                <IconButton
                  size="small"
                  className={`header-action-btn ${editMode ? "active" : ""}`}
                  onClick={handleToggleEditMode}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={dragMode ? "Desactivar arrastrar" : "Reordenar filtros"}
              >
                <IconButton
                  size="small"
                  className={`header-action-btn ${dragMode ? "active" : ""}`}
                  onClick={handleToggleDragMode}
                >
                  <DragIndicatorIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {/* Contenido principal con DragDropContext */}
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            {filterData.map((section, sectionIndex) => (
              <div key={`section-${sectionIndex}`} className="menu-section">
                {sectionIndex > 0 && <div className={`divider ${theme}`} />}

                <div className={`section-header ${theme}`}>
                  {section.sectionTitle}
                </div>

                <div className={`divider ${theme}`} />

                <Droppable
                  droppableId={`category-${section.category}`}
                  type="FILTER"
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {section.items.map((item, idx) => (
                        <FilterItem
                          key={`filter-${item.id}`}
                          item={{
                            ...item,
                            category: section.category,
                            position: idx,
                          }}
                          theme={theme}
                          onItemSelected={handleItemSelected}
                          selectedFilters={selectedFilters}
                          editMode={editMode}
                          onEdit={handleEditFilter}
                          onDelete={handleDeleteFilter}
                          draggable={dragMode}
                          index={idx}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </DragDropContext>
        </div>
      )}

      {/* Diálogo de confirmación */}
      <ConfirmDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        message={confirmDialog.message}
        onConfirm={() => {
          confirmDialog.onConfirm();
          setConfirmDialog({ ...confirmDialog, open: false });
        }}
        onCancel={() => setConfirmDialog({ ...confirmDialog, open: false })}
      />
    </div>
  );
};

FilterMenu.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  filterData: PropTypes.arrayOf(
    PropTypes.shape({
      sectionTitle: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.oneOf(["folder", "option"]).isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          items: PropTypes.arrayOf(PropTypes.object),
        })
      ).isRequired,
    })
  ).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  roleId: PropTypes.number.isRequired,
};

export default FilterMenu;
