// TripSelector.jsx
import React, { useEffect } from 'react';
import '../../assets/scss/components/maps/TripSelector.scss';

const TripSelector = ({ 
  trips, 
  onSelectTrip, 
  selectedTrip, 
  showVehicleInfo = false, 
  hideTitle = false
}) => {
  // Validación de viajes (mantiene la lógica existente)
  const validTrips = trips.filter(trip => 
    trip && trip.origin && trip.destination &&
    trip.origin.lat && trip.origin.lon &&
    trip.destination.lat && trip.destination.lon
  );
  
  useEffect(() => {
    console.debug("TripSelector recibió:", {
      tripsCount: trips?.length || 0,
      validTripsCount: validTrips.length
    });
  }, [trips, validTrips]);

  if (!validTrips || validTrips.length === 0) {
    return (
      <div className="trip-selector empty">
        <p>No hay viajes disponibles para esta fecha/vehículo</p>
      </div>
    );
  }

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    const [date, time] = dateTimeStr.split(' ');
    return time || date;
  };

  const isSameTrip = (trip1, trip2) => {
    if (!trip1 || !trip2) return false;
    return (
      trip1.origin?.dateTime === trip2.origin?.dateTime &&
      trip1.destination?.dateTime === trip2.destination?.dateTime &&
      trip1.vehiclePlate === trip2.vehiclePlate
    );
  };

  return (
    <div className="trip-selector">
      {!hideTitle && (
        <h3>Viajes disponibles ({validTrips.length})</h3>
      )}
      
      <div className="trips-list">
        {validTrips.map((trip, index) => (
          <div 
            key={`trip-${index}-${trip.vehiclePlate || 'unknown'}`}
            className={`trip-item ${isSameTrip(selectedTrip, trip) ? 'selected' : ''}`}
            onClick={() => onSelectTrip(trip)}
          >
            <div className="trip-header">
              <span className="trip-index">{index + 1}</span>
              <span className="trip-route">{trip.routeName}</span>
              {showVehicleInfo && trip.vehiclePlate && (
                <span className="trip-vehicle">{trip.vehiclePlate}</span>
              )}
            </div>
            
            <div className="trip-times">
              <div className="trip-time">
                <span className="label">Salida:</span>
                <span className="time">{formatDateTime(trip.origin?.dateTime)}</span>
              </div>
              <div className="trip-time">
                <span className="label">Llegada:</span>
                <span className="time">{formatDateTime(trip.destination?.dateTime)}</span>
              </div>
            </div>
            
            {/* Primera fila de estadísticas */}
            <div className="trip-stats">
              <div className="trip-stat">
                <span className="icon">🛣️</span>
                <span className="value">{trip.distanceTraveledKm} km</span>
              </div>
              <div className="trip-stat">
                <span className="icon">⏱️</span>
                <span className="value">{trip.durationMinutes} min</span>
              </div>
              <div className="trip-stat">
                <span className="icon">⚠️</span>
                <span className="value">{(trip.overspeedCount || 0) + (trip.criticalViolationsCount || 0)}</span>
              </div>
            </div>
            
            {/* Nueva fila de estadísticas con eventos especiales */}
            <div className="trip-stats secondary-stats">
              <div className="trip-stat">
                <span className="icon">🏎️</span>
                <span className="value" title="Velocidad máxima">
                  {trip.maxSpeed || 0} km/h
                </span>
              </div>
              <div className="trip-stat">
                <span className="icon">🔺</span>
                <span className="value" title="Aceleraciones bruscas">
                  {trip.harshAccelerationCount || 0}
                </span>
              </div>
              <div className="trip-stat">
                <span className="icon">🔻</span>
                <span className="value" title="Frenadas bruscas">
                  {trip.harshBrakingCount || 0}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TripSelector;